import React, { Fragment } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import AppRouter from "./routes/AppRouter/AppRouter";
import { PersonalInformationContextProvider } from './context/PersonalInformationContext';
import { EducationContextProvider } from './context/EducationContext';
import { ModalityAndCareerContextProvider } from './context/ModalityAndCareerContext';
import { ApplicationContextProvider } from './context/ApplicationContext';
import { DocumentsContextProvider } from './context/DocumentsContext';
import './App.css';
import './styles/global/theme.css';
import theme from "./styles/theme/themeConfig";
import { StyledEngineProvider } from '@mui/material/styles';
import './i18n/i18n';
import { SCROLLABLE_AREA_NAME } from './utils/CommonFunctions';

import BG1B from './assets/images/slideshow/big/imagenes_landing_1440x1024_1.png';
import BG2B from './assets/images/slideshow/big/imagenes_landing_1440x1024_2.png';
import BG3B from './assets/images/slideshow/big/imagenes_landing_1440x1024_3.png';
import BG4B from './assets/images/slideshow/big/imagenes_landing_1440x1024_4.png';
import BG5B from './assets/images/slideshow/big/imagenes_landing_1440x1024_5.png';
import BG6B from './assets/images/slideshow/big/imagenes_landing_1440x1024_6.png';
import BG7B from './assets/images/slideshow/big/imagenes_landing_1440x1024_7.png';
import BG8B from './assets/images/slideshow/big/imagenes_landing_1440x1024_8.png';
import BG9B from './assets/images/slideshow/big/imagenes_landing_1440x1024_9.png';
import BG10B from './assets/images/slideshow/big/imagenes_landing_1440x1024_10.png';
import BG11B from './assets/images/slideshow/big/imagenes_landing_1440x1024_11.png';
import BG12B from './assets/images/slideshow/big/imagenes_landing_1440x1024_12.png';
import BG13B from './assets/images/slideshow/big/imagenes_landing_1440x1024_13.png';
import BG14B from './assets/images/slideshow/big/imagenes_landing_1440x1024_14.png';
import BG15B from './assets/images/slideshow/big/imagenes_landing_1440x1024_15.png';
import BG16B from './assets/images/slideshow/big/imagenes_landing_1440x1024_16.png';
import BG17B from './assets/images/slideshow/big/imagenes_landing_1440x1024_17.png';


import BG1S from './assets/images/slideshow/small/imagen_landing_144x120_1.png';
import BG2S from './assets/images/slideshow/small/imagen_landing_144x120_2.png';
import BG3S from './assets/images/slideshow/small/imagen_landing_144x120_3.png';
import BG4S from './assets/images/slideshow/small/imagen_landing_144x120_4.png';
import BG5S from './assets/images/slideshow/small/imagen_landing_144x120_5.png';
import BG6S from './assets/images/slideshow/small/imagen_landing_144x120_6.png';
import BG7S from './assets/images/slideshow/small/imagen_landing_144x120_7.png';
import BG8S from './assets/images/slideshow/small/imagen_landing_144x120_8.png';
import BG9S from './assets/images/slideshow/small/imagen_landing_144x120_9.png';
import BG10S from './assets/images/slideshow/small/imagen_landing_144x120_10.png';
import BG11S from './assets/images/slideshow/small/imagenes_landing_144x102_11.png';
import BG12S from './assets/images/slideshow/small/imagenes_landing_144x102_12.png';
import BG13S from './assets/images/slideshow/small/imagenes_landing_144x102_13.png';
import BG14S from './assets/images/slideshow/small/imagenes_landing_144x102_14.png';
import BG15S from './assets/images/slideshow/small/imagenes_landing_144x102_15.png';
import BG16S from './assets/images/slideshow/small/imagenes_landing_144x102_16.png';
import BG17S from './assets/images/slideshow/small/imagenes_landing_144x102_17.png';

import { useEffect } from 'react';

const slideshow = [
  {
    small: BG1S,
    big: BG1B,
    loaded: false,
  },
  {
    small: BG2S,
    big: BG2B,
    loaded: false,
  },
  {
    small: BG3S,
    big: BG3B,
    loaded: false,
  },
  {
    small: BG4S,
    big: BG4B,
    loaded: false,
  }
  ,
  {
    small: BG5S,
    big: BG5B,
    loaded: false,
  },
  {
    small: BG6S,
    big: BG6B,
    loaded: false,
  },
  {
    small: BG7S,
    big: BG7B,
    loaded: false,
  },
  {
    small: BG8S,
    big: BG8B,
  },
  {
    small: BG9S,
    big: BG9B,
  },
  {
    small: BG10S,
    big: BG10B,
  },
  {
    small: BG11S,
    big: BG11B,
  },
  {
    small: BG12S,
    big: BG12B,
  },
  {
    small: BG13S,
    big: BG13B,
  },
  {
    small: BG14S,
    big: BG14B,
  },
  {
    small: BG15S,
    big: BG15B,
  },
  {
    small: BG16S,
    big: BG16B,
  },
  {
    small: BG17S,
    big: BG17B,
  }
]

const App = () =>
{
  useEffect(() =>
  {

    const screenWidth = window.innerWidth;
    if (screenWidth < 600) return;
    let slides = document.getElementsByClassName('carousel-item');

    function addActive(slide)
    {
      slide.classList.add('active');
    }

    function removeActive(slide)
    {
      slide.classList.remove('active');
    }

    // Activar el primer slide
    addActive(slides[0]);

    // Iniciar el intervalo de cambio de slides
    const intervalId = setInterval(() =>
    {
      for (let i = 0; i < slides.length; i++)
      {
        if (i + 1 === slides.length)
        {
          addActive(slides[0]);
          setTimeout(removeActive, 1000, slides[i]);
          break;
        }
        if (slides[i].classList.contains('active'))
        {
          setTimeout(removeActive, 1000, slides[i]);
          addActive(slides[i + 1]);
          break;
        }
      }
    }, 5000);

    // Limpiar el intervalo cuando el componente se desmonte
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() =>
  {
    let slides = document.getElementsByClassName('slide-image');
    Array.from(slides).forEach(slide =>
    {
      const highResSrc = slide.getAttribute('data-hr');

      const imgHighRes = new Image();
      imgHighRes.src = highResSrc;

      imgHighRes.onload = () =>
      {
        slide.src = highResSrc;
        slide.classList.remove('slide-image-blurred');
      };
    });
  }, []);

  return (
    <div>
      {/* <img className='desktop-background' alt='Background' src={BG1B} /> */}

      <div className="desktop-background">
        {
          slideshow.map((bg, index) => (
            <Fragment key={index}>
              <div className="carousel-item">
                <img className='slide-image slide-image-blurred'
                  alt={`Background ${index}`}
                  data-hr={bg.big}
                  src={bg.small} />
                {/* <div className="slide-image"
                  style={{ backgroundImage: `url('${bg.big}')` }}>
                </div> */}
              </div>
            </Fragment>
          ))
        }
      </div>


      <div className='mobile-view' id={SCROLLABLE_AREA_NAME}>
        <div style={{ position: 'relative' }}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <ApplicationContextProvider>
                <PersonalInformationContextProvider>
                  <EducationContextProvider>
                    <ModalityAndCareerContextProvider>
                      <DocumentsContextProvider>
                        <AppRouter onError={(error) => console.log(error)} />
                      </DocumentsContextProvider>
                    </ModalityAndCareerContextProvider>
                  </EducationContextProvider>
                </PersonalInformationContextProvider>
              </ApplicationContextProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </div>
      </div>
    </div>
  );
};

export default App;