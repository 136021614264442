import { CheckCircle, RadioButtonChecked, RemoveCircle } from "@mui/icons-material";
import { Typography } from "@mui/material";
import React from "react";
export default class ApplicationSuspendStepIndicator extends React.Component
{
    static defaultProps = {
        step: 0,
        currentStep: 0,
        text: "",
    }

    render ()
    {
        return (
            <>
                {
                    this.props.step < this.props.currentStep ?
                        <>
                            <CheckCircle htmlColor="#FF5117" />
                            <Typography variant="h5" component="p" color="#FF5117">
                                {this.props.text}
                            </Typography>
                        </> : <></>
                }
                {
                    this.props.step === this.props.currentStep ?
                        <>
                            <RadioButtonChecked htmlColor="#FF5117" />
                            <Typography variant="h5" component="p">
                                {this.props.text}
                            </Typography>
                        </> : <></>
                }
                {
                    this.props.step > this.props.currentStep ?
                        <>
                            <RemoveCircle htmlColor="#62616E" />
                            <Typography variant="h5" component="p" color="#62616E">
                                {this.props.text}
                            </Typography>
                        </> : <></>
                }
            </>
        )
    }
}