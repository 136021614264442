import * as React from 'react';
import { Grid, Typography } from "@mui/material";
import UlimaImage from "../../assets/icons/ulimaVertical.svg"
import MenuImage from "../../assets/images/cabecera_formulario_1200x400.jpg"
import './Welcome.css';
import { useNavigate } from "react-router-dom";
import * as ROUTES from "../../routes/routes";
import { useTranslation } from "react-i18next";
import ULButton from '../../components/ULButton';

const Welcome = (props) =>
{
    const { t } = useTranslation();
    const navigate = useNavigate();

    React.useEffect(() => {
        sessionStorage.clear();
    }, [])

    return (
        <Grid container direction="column" style={{ backgroundColor: 'white' }} spacing={2}>
            <Grid itemxs={12} style={{ marginLeft: '1rem', marginRight: '1rem', marginTop: '1rem' }} className="imageLayoutTopBar">
                <img src={UlimaImage} alt={t('top-bar.ulima-logo-alt')} className="imageSchool" />
            </Grid>
            <Grid item xs={12} style={{ marginLeft: '1rem', marginRight: '1rem' }}>
                <div className="body2"> {t('menu.title')} </div>
            </Grid>

            <Grid item xs={12}>
                <img src={MenuImage} style={{ width: '100%' }} alt={t('layout.menu-alt')} className="imageLayoutBody" />
            </Grid>

            <Grid item xs={12} style={{ marginLeft: '1rem', marginRight: '1rem' }}>
                <Typography variant='h5' element='h4'>{t('menu.subtitle')} </Typography>
            </Grid>

            <Grid item xs={12} style={{ marginLeft: '1rem', marginRight: '1rem' }}>
                <ULButton onClick={() => navigate(ROUTES.APPLICATION_NEW_APPLICANT)}>
                    {t('menu.new')}
                </ULButton>
            </Grid>

            <Grid item xs={12} style={{ marginLeft: '1rem', marginRight: '1rem', marginBottom: '1rem' }}>
                <ULButton variant="outlined" onClick={() => navigate(ROUTES.APPLICATION_CONTINUE)}>
                    {t('buttons.continue-application')}
                </ULButton>
            </Grid>

        </Grid>
    )
}

export default Welcome;