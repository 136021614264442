export const PRIMARY = "#FF5117";
export const SECONDARY = '#555555';
export const DEFAULT_WHITE = "#FFFFFF";
export const NEUTRAL = "#9E9E9E";
export const SUCCESS = "#2E7D32";
export const WARNING = "#F5C518";
export const ERROR = "#D32F2F";
export const DEFAULT_BLACK = "#000000";
export const BACKGROUND = "#D3D3D3"

