import * as React from 'react';
import { Button, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const ButtonContainThemed = styled(Button)(({ theme }) => ({
    borderRadius: '2rem',
    padding: '0.6rem 1rem',
    fontSize: '0.875rem',
}));

const ULButton = ({ working, goBack, ...props }) =>
{
    return (
        <ButtonContainThemed type='button' {...props} disabled={working ? true : props.disabled} fullWidth={props.fullWidth}>{working ? <CircularProgress size='1rem' style={{ marginRight: '.5rem' }} /> : <></>}{goBack ? <ArrowBackIcon className="arrow-back" style={{ marginRight: '.5rem' }}></ArrowBackIcon> : <></>}{props.children}</ButtonContainThemed>
    )

};

ULButton.defaultProps = {
    variant: "contained",
    goBack: false,
    working: false,
    fullWidth: true,
}

export default ULButton;