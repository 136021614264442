import React from "react";
import './loading.css';
export default class Loading extends React.Component
{
    render ()
    {
        return (
            <>
                <div className="loading-spinner-container">
                        <div className="ul-loading-spinner">
                            <div className="ul-loading-animation">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                </div>
            </>
        )
    }
}