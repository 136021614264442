
import { Alert, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import * as ROUTES from "../../routes/routes";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from "react";
import TextField from '@mui/material/TextField';
import * as APIServices from "../../services/rest/APIServices";
import ULButton from "../../components/ULButton";
import Loading from "../../components/Loading";
import { SCROLLABLE_AREA_NAME, protectEmail, protectPhoneNumber } from "../../utils/CommonFunctions";
import { useEffect } from "react";
import { handlePreventTypingChars } from "../../utils/Validations";
import ApplicationContext from "../../context/ApplicationContext";
import EducationContext from "../../context/EducationContext";
import ModalityAndCareerContext from "../../context/ModalityAndCareerContext";

const ApplicationContinue = (props) =>
{
    const navigate = useNavigate();
    const { t } = useTranslation();
    const location = useLocation();
    const [omr, setOMR] = useState(location.state?.omr ?? "");
    const [documentType, setDocumentType] = useState(location.state?.documentType ?? 0);
    const [documentNumber, setDocumentNumber] = useState(location.state?.documentNumber ?? "");
    const [requestCodeType, setRequestCodeType] = useState(0);
    const [requestCodeTypeList, setRequestCodeTypeList] = useState([]);
    const [documentTypeList, setDocumentTypeList] = useState([]);
    const [errMessages, setErrMessages] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [working, setWorking] = useState(false);
    const { setApplicationID, setCallID } = useContext(ApplicationContext);
    const { fifthGradeSchool, setFifthGradeSchool, fourthGradeSchool, setFourthGradeSchool, thirdGradeSchool, setThirdGradeSchool } = useContext(EducationContext);
    const { universityData, setUniversityData } = useContext(ModalityAndCareerContext);

    const requestData = () =>
    {
        setWorking(true);
        setErrMessages([]);
        APIServices.continueApplication({
            documentTypeId: documentType,
            documentNumber: documentNumber,
            omr: omr,
            requestTypeId: requestCodeType,
        })
            .then((response) =>
            {
                const email = response.data.data.email ?? '';
                const cellphone = response.data.data.cellphone ?? '';
                const step = response.data.data.step ?? 0;
                const callId = response.data.data.callId ?? 0;
                const fromApplicationId = response.data.data.applicationId ?? 0;
                const fromFifthGradeApplicantSchoolId = response.data.data.fifthGradeApplicantSchoolId ?? 0;
                const fromFifthGradeSameSchool = response.data.data.fifthGradeSameSchool ?? false;
                const fromFifthGradeNotFound = response.data.data.fifthGradeNotFound ?? false;
                const fromFourthGradeApplicantSchoolId = response.data.data.fourthGradeApplicantSchoolId ?? 0;
                const fromFourthGradeSameSchool = response.data.data.fourthGradeSameSchool ?? false;
                const fromFourthGradeNotFound = response.data.data.fourthGradeNotFound ?? false;
                const fromThirdGradeApplicantSchoolId = response.data.data.thirdGradeApplicantSchoolId ?? 0;
                const fromUniversityNotFound = response.data.data.universityNotFound ?? false;

                setApplicationID(fromApplicationId);
                setCallID(callId);
                setFifthGradeSchool({
                    ...fifthGradeSchool,
                    applicantSchoolId: fromFifthGradeApplicantSchoolId,
                    sameSchool: fromFifthGradeSameSchool,
                    notFound: fromFifthGradeNotFound,
                })
                setFourthGradeSchool({
                    ...fourthGradeSchool,
                    applicantSchoolId: fromFourthGradeApplicantSchoolId,
                    sameSchool: fromFourthGradeSameSchool,
                    notFound: fromFourthGradeNotFound,
                })
                setThirdGradeSchool({
                    ...thirdGradeSchool,
                    applicantSchoolId: fromThirdGradeApplicantSchoolId,
                })
                setUniversityData({
                    ...universityData,
                    notFound: fromUniversityNotFound,
                })

                const showCode = response.data?.data?.showCode ?? false;
                const code = response.data?.data?.code ?? '';
                const requestType = requestCodeTypeList.find((requestType) => (requestType.requestTypeId === requestCodeType));
                let instructions = requestType?.message.replace('<:email>', protectEmail(email));
                instructions = instructions.replace('<:phone>', protectPhoneNumber(cellphone));
                navigate(ROUTES.STUDENT_CODE_VALIDATION, { state: { instructions: instructions, cellphone: cellphone, email: email, requestTypeId: requestCodeType, step: step - 1, fromApplicationId: fromApplicationId, from: 'continue', showCode: showCode, codePreview: code } });
            })
            .catch((error) =>
            {
                console.log(error);
                setErrMessages(error.response ? error.response.data?.messages ?? [{ code: "", message: t('errors.unknown') }] : [{ code: "", message: t('errors.network') }])
                const scrollableArea = document.getElementById(SCROLLABLE_AREA_NAME);
                scrollableArea.scrollTo({ top: 0, behavior: 'smooth' });
                window.scrollTo({ top: 0, behavior: 'smooth' });
            })
            .finally(() =>
            {
                setWorking(false);
            })
    }

    const handleContinue = () =>
    {
        requestData();
    }

    const handleChangeDocumentType = (e) =>
    {
        setDocumentType(parseInt(e.target.value));
    }

    useEffect(() =>
    {
        const callServices = async () =>
        {
            setIsLoading(true);
            await APIServices.getRequestCodeTypeList().then((response) =>
            {
                setRequestCodeTypeList(response.data.data?.list ?? []);
            })
                .catch((error) =>
                {
                    setErrMessages(error.response ? (error.response.data?.messages ?? [{ code: "", message: t('errors.unknown') }]) : [{ code: "", message: t('errors.network') }]);
                    const scrollableArea = document.getElementById(SCROLLABLE_AREA_NAME);
                    scrollableArea.scrollTo({ top: 0, behavior: 'smooth' });
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                })
                .finally(() =>
                {
                    setIsLoading(false);
                })
        }
        callServices();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() =>
    {
        const callServices = async () =>
        {
            setIsLoading(true);
            await APIServices.getDocumentTypeList().then((response) =>
            {
                setDocumentTypeList(response.data.data?.list ?? []);
            })
                .catch((error) =>
                {
                    setErrMessages(error.response ? (error.response.data?.messages ?? [{ code: "", message: t('errors.unknown') }]) : [{ code: "", message: t('errors.network') }]);
                    const scrollableArea = document.getElementById(SCROLLABLE_AREA_NAME);
                    scrollableArea.scrollTo({ top: 0, behavior: 'smooth' });
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                })
                .finally(() =>
                {
                    setIsLoading(false);
                })
        }
        callServices();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isDNISelected = documentTypeList.find(dt => dt.documentTypeId === documentType)?.isMain ?? false;

    return (
        <>
            {
                isLoading ?
                    <>
                        <Loading />
                    </> :
                    <>
                        <Grid container direction="column" spacing={2}>
                            {
                                errMessages?.length ?
                                    <Grid item xs={12}>
                                        <Alert role="alert" severity="error">
                                            {
                                                errMessages.map((err, index) => (
                                                    <Typography key={index}> {err.code?.length ? `[${err.code}]` : ""} {err.message}</Typography>
                                                ))
                                            }
                                        </Alert>
                                    </Grid>
                                    : <></>
                            }
                            <Grid item xs={12}>
                                <Typography variant="h5" component="h4" style={{ textDecoration: 'underline', fontWeight: '500' }}>
                                    {t('application-continue.title')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h5" component="h5">
                                    {t('application-continue.omr-and-id-label')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField inputProps={{ maxLength: 8 }} onChange={(e) => setOMR(e.target.value)} value={omr} fullWidth label={t('application-continue.omr-field-placeholder')} />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel>{t('identification.document-type-placeholder')}</InputLabel>
                                    <Select
                                        value={documentType === 0 ? undefined : documentType}
                                        onChange={handleChangeDocumentType}
                                    >
                                        {
                                            documentTypeList?.map((documentType, index) => (
                                                <MenuItem key={index} value={documentType.documentTypeId}>{documentType.name}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField inputProps={{ maxLength: isDNISelected ? 8 : undefined }} onChange={(e) => setDocumentNumber(e.target.value)} onKeyDown={handlePreventTypingChars} value={documentNumber} fullWidth label={t('identification.document-number-placeholder')} />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h5" component="h5">
                                    {t('application-continue.verification-type')}
                                </Typography>
                                <FormControl fullWidth>
                                    <RadioGroup
                                        value={requestCodeType}
                                        onChange={(e) =>
                                        {
                                            setRequestCodeType(parseInt(e.target.value))
                                        }}
                                    >
                                        {
                                            requestCodeTypeList?.map((requestCodeType, index) => (
                                                <FormControlLabel key={index} className="radio-button" value={requestCodeType.requestTypeId} control={<Radio />} label={requestCodeType.name} />
                                            ))
                                        }
                                    </RadioGroup>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <ULButton disabled={
                                    requestCodeType === 0 || documentType === 0 || !(isDNISelected ? documentNumber?.length === 8 : (documentNumber?.length > 0 && documentNumber?.length < 20)) || !(omr?.length === 8)
                                } working={working} onClick={handleContinue}>{t('buttons.continue')}</ULButton>
                            </Grid>

                            <Grid item xs={12}>
                                <ULButton variant="outlined" onClick={() => navigate(ROUTES.MENU)} goBack={true}>{t('buttons.back-to-menu')}</ULButton>
                            </Grid>

                            <Grid item xs={12}>
                                <ULButton variant="outlined" color="secondary" onClick={() => navigate(ROUTES.APPLICATION_RECOVER_OMR, { state: { documentType: documentType, documentNumber: documentNumber } })}>
                                    {t('buttons.forgot-omr')}
                                </ULButton>
                            </Grid>

                        </Grid>
                    </>
            }
        </>
    )
}

export default ApplicationContinue;