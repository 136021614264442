import { Grid, Typography, Box, Dialog, DialogActions, DialogContent, DialogTitle, Alert, Divider, Checkbox, FormControlLabel } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from 'react-router-dom';
import * as ROUTES from "../../routes/routes";
import { useEffect } from "react";
import Slide from '@mui/material/Slide';
import FileUploadManager from '../../components/FileUploadManagerVerification';
import { CloudUploadOutlined } from '@mui/icons-material';
import EducationContext from '../../context/EducationContext';
import * as APIServices from "../../services/rest/APIServices";
import ApplicationContext from '../../context/ApplicationContext';
import { Fragment } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Loading from '../../components/Loading';
import { useState } from 'react';
import { useContext } from 'react';
import { forwardRef } from 'react';
import ULButton from '../../components/ULButton';
import { SCROLLABLE_AREA_NAME } from '../../utils/CommonFunctions';
import ModalityAndCareerContext from '../../context/ModalityAndCareerContext';
import DocumentsContext from '../../context/DocumentsContext';

export const VERIFICATION_PENDING = 1;
export const VERIFICATION_VALID = 2;
export const VERIFICATION_INVALID = 3;


const BoxUploadWrapper = styled(Box)(
  ({ theme }) => `
    border-radius: 5px;
    padding: 2;
    margin-top: 2;
    background: ${theme.palette.background};
    border: 1px dashed #000000;
    outline: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    transition: ${theme.transitions.create(['border', 'background'])};

    &:hover {
      background: ${theme.palette.background};
      border-color: ${theme.palette.primary.main};
    }
`
);

const UploadBox = styled(Box)(
  ({ theme }) => `
    border-radius: '6px';
    padding: 2;
    background: ${theme.palette.background};
`
);

const Transition = forwardRef(function Transition(props, ref)
{
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function VerifiedDocuments()
{
  const [openAgreementModal, setOpenAgreementModal] = useState(false);

  const { applicationID } = useContext(ApplicationContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [requirements, setRequirements] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errMessages, setErrMessages] = useState([]);
  const [working, setWorking] = useState(false);
  const [canEdit, setCanEdit] = useState(true);

  const { fifthGradeSchool, fourthGradeSchool, thirdGradeSchool } = useContext(EducationContext);
  const { universityData } = useContext(ModalityAndCareerContext);
  const [filesFifthNF, setFilesFifthNF] = useState([]);
  const [canUploadFifthNF, setCanUploadFifthNF] = useState(true);
  const [filesFourthNF, setFilesFourthNF] = useState([]);
  const [canUploadFourthNF, setCanUploadFourthNF] = useState(true);
  const [filesThirdNF, setFilesThirdNF] = useState([]);
  const [canUploadThirdNF, setCanUploadThirdNF] = useState(true);
  const [universityFiles, setUniversityFiles] = useState([]);
  const [canUploadUniversity, setCanUploadUniversity] = useState([]);

  const { documentsTermsAndConditions, setDocumentsTermsAndConditions } = useContext(DocumentsContext);
  const [disclaimersList, setDisclaimersList] = useState([]);

  const handleCloseAgreementModal = () =>
  {
    setOpenAgreementModal(false);
  };

  const handleAgreeTerms = () =>
  {
    setOpenAgreementModal(false);
    setDocumentsTermsAndConditions(true);
  };

  const handleDisagreeTerms = () =>
  {
    setOpenAgreementModal(false);
    setDocumentsTermsAndConditions(false);
  };

  const handleTermsAndConditionsChange = () =>
  {
    if (documentsTermsAndConditions)
    {
      setDocumentsTermsAndConditions(false)
    }
    else
    {
      setOpenAgreementModal(true);
    }
  }

  const verifyPicture = (e, requirementId) =>
  {
    e.stopPropagation();
    navigate(`${ROUTES.DOCUMENTS_CARNE_PHOTO}?requirementId=${requirementId}&from=verifications`);
  }

  const handleContinue = (e) =>
  {
    navigate(ROUTES.APPLICANTS_CARD);
  }

  const handleFinishVerification = (e) =>
  {
    setWorking(true);
    setErrMessages([]);
    APIServices.verificationFinishCorrection(applicationID)
      .then((response) =>
      {
        const scrollableArea = document.getElementById(SCROLLABLE_AREA_NAME);
        scrollableArea.scrollTo({ top: 0, behavior: 'smooth' });
        window.scrollTo({ top: 0, behavior: 'smooth' });
        window.location.reload();
      })
      .catch((error) =>
      {
        setErrMessages(error.response ? error.response.data?.messages ?? [{ code: "", message: t('errors.unknown') }] : [{ code: "", message: t('errors.network') }])
        const scrollableArea = document.getElementById(SCROLLABLE_AREA_NAME);
        scrollableArea.scrollTo({ top: 0, behavior: 'smooth' });
        window.scrollTo({ top: 0, behavior: 'smooth' });
      })
      .finally(() =>
      {
        setWorking(false);
      })
  }

  /*********************/
  const listRequirements = () =>
  {
    setIsLoading(true);
    APIServices.listFilesVerificationDC(applicationID)
      .then((response) =>
      {
        const inCorrection = response.data.data?.inCorrection;
        const requirements = response.data.data?.requirements;
        const fifthGradeSchoolDocuments = response.data.data?.fifthGradeSchoolDocuments;
        const fourthGradeSchoolDocuments = response.data.data?.fourthGradeSchoolDocuments;
        const thirdGradeSchoolDocuments = response.data.data?.thirdGradeSchoolDocuments;
        const universityDocuments = response.data.data?.universityDocuments;
        if (requirements?.length)
        {
          setRequirements(requirements.map(requirement =>
          {
            const { applicationDocuments, templates, ...rest } = requirement;
            const updatedFiles = applicationDocuments?.map((file) =>
            {
              const { fileName, ...fileRest } = file;
              return ({
                ...fileRest,
                name: fileName,
                uploaded: fileName?.length ? true : false,
                documentState: fileRest.documentState,
              })
            })
            const updatedTemplates = templates?.map((template) => ({
              ...template,
              downloaded: false,
            }))
            return {
              ...rest,
              templates: updatedTemplates,
              files: updatedFiles,
              canUpload: (updatedFiles?.some(file => file.documentState === VERIFICATION_INVALID) || updatedFiles.length === 0) ?? false,
            }
          }));
        }
        if (fifthGradeSchoolDocuments?.length)
        {
          setFilesFifthNF(fifthGradeSchoolDocuments.map(file =>
          {
            const { fileName, ...rest } = file;
            return { ...rest, name: fileName, uploaded: fileName.length ? true : false };
          }));
          setCanUploadFifthNF(fifthGradeSchoolDocuments?.some(file => file.documentState === VERIFICATION_INVALID) ?? false);
        }
        if (fourthGradeSchoolDocuments?.length)
        {
          setFilesFourthNF(fourthGradeSchoolDocuments.map(file =>
          {
            const { fileName, ...rest } = file;
            return { ...rest, name: fileName, uploaded: fileName.length ? true : false };
          }));
          setCanUploadFourthNF(fourthGradeSchoolDocuments?.some(file => file.documentState === VERIFICATION_INVALID) ?? false);
        }
        if (thirdGradeSchoolDocuments?.length)
        {
          setFilesThirdNF(thirdGradeSchoolDocuments.map(file =>
          {
            const { fileName, ...rest } = file;
            return { ...rest, name: fileName, uploaded: fileName.length ? true : false };
          }));
          setCanUploadThirdNF(thirdGradeSchoolDocuments?.some(file => file.documentState === VERIFICATION_INVALID) ?? false);
        }
        if (universityDocuments?.length)
        {
          setUniversityFiles(universityDocuments.map(file =>
          {
            const { fileName, ...rest } = file;
            return { ...rest, name: fileName, uploaded: fileName.length ? true : false };
          }));
          setCanUploadUniversity(universityDocuments?.some(file => file.documentState === VERIFICATION_INVALID) ?? false);
        }

        setCanEdit(inCorrection ?? false)
        setDocumentsTermsAndConditions(true);
      })
      .catch((error) =>
      {
        setErrMessages(error.response ? error.response.data?.messages ?? [{ code: "", message: t('errors.unknown') }] : [{ code: "", message: t('errors.network') }])
        const scrollableArea = document.getElementById(SCROLLABLE_AREA_NAME);
        scrollableArea.scrollTo({ top: 0, behavior: 'smooth' });
        window.scrollTo({ top: 0, behavior: 'smooth' });
      })
      .finally(() =>
      {
        setIsLoading(false);
      })
  }

  const uploadFile = (file, requirementId, index) =>
  {
    const options = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent) =>
      {
        const { loaded, total } = progressEvent;
        const percentCompleted = Math.round((loaded * 100) / total);
        setRequirements((prevRequirements) =>
        {
          const newRequirements = [...prevRequirements];
          const fobj = newRequirements[index].files.find(obj => obj.tempId === file.tempId);
          if (fobj)
          {
            fobj.progress = percentCompleted
          };
          return newRequirements;
        });
      },
    }
    const data = new FormData();
    data.append('file', file.bytes);
    data.append('applicationId', applicationID);
    data.append('requirementId', requirementId);
    setRequirements((prevRequirements) =>
    {
      const newRequirements = [...prevRequirements];
      const fobj = newRequirements[index].files.find(obj => obj.tempId === file.tempId);
      if (fobj) fobj.errMessages = [];
      return newRequirements;
    });

    // Subir archivo
    APIServices.uploadFileDC(data, options)
      .then((response) =>
      {
        const data = response.data.data;
        setRequirements((prevRequirements) =>
        {
          const newRequirements = [...prevRequirements];
          const fobj = newRequirements[index].files.find(obj => obj.tempId === file.tempId);
          if (fobj)
          {
            fobj.uploaded = true;
            fobj.uuid = data.uuid;
            fobj.sizeText = data.sizeText;
            fobj.errMessages = [];
            fobj.documentState = VERIFICATION_PENDING;
            delete fobj.tempId;
            delete fobj.progress;
          }
          return newRequirements;
        });
      })
      .catch((error) =>
      {
        const errMessages = error.response ? error.response.data?.messages ?? { code: "", message: t('errors.unknown') } : { code: "", message: t('errors.network') };
        setRequirements((prevRequirements) =>
        {
          const newRequirements = [...prevRequirements];
          const fobj = newRequirements[index].files.find(obj => obj.tempId === file.tempId);
          if (fobj)
          {
            fobj.uploaded = true;
            fobj.errMessages = errMessages;
          }
          return newRequirements;
        });
      })
  }

  const wasAlreadyUploaded = (requirements, accepted) =>
  {
    return requirements.some(requirement => requirement.files.some(file => file.name === accepted.name));
  }

  const handleSetFiles = (index, accepted) =>
  {
    setRequirements((prevRequirements) =>
    {
      const newRequirements = [...prevRequirements];
      newRequirements[index].errMessages = []
      accepted.forEach((file) =>
      {
        // Verificar si el archivo fue cargado anteriormente
        if (!wasAlreadyUploaded([...newRequirements], file))
        {
          const uuid = uuidv4();
          const ufile = {
            bytes: file,
            name: file.name,
            tempId: uuid,
            uploaded: false,
            progress: 0,
            canEdit: true,
          };
          newRequirements[index].files.push(ufile);
          setTimeout(() => uploadFile(ufile, newRequirements[index].requirementId, index), 500);
        }
        else
        {
          newRequirements[index].errMessages.push({ code: "", message: t('requested-documents.file-already-exists-error', { filename: accepted[0].name }) });
        }
      });
      return newRequirements;
    })
  }

  const handleDeleteFile = (file, index) =>
  {
    const uuid = file.uuid;
    const requirementId = requirements[index].requirementId;
    if (uuid)
    {
      setRequirements((prevRequirements) =>
      {
        const newRequirements = [...prevRequirements];
        const fobj = newRequirements[index].files.find(obj => obj.uuid === uuid);
        fobj.errMessages = [];
        return newRequirements;
      });
      APIServices.deleteFileDC({
        applicationId: applicationID,
        requirementId: requirementId,
        uuid: uuid,
      })
        .then((response) =>
        {
          setRequirements((prevRequirements) =>
          {
            const newRequirements = [...prevRequirements];
            const filteredFiles = newRequirements[index].files.filter(obj => obj.uuid !== uuid);
            newRequirements[index].files = filteredFiles;
            return newRequirements;
          });
        })
        .catch((error) =>
        {
          const errMessages = error.response ? error.response.data?.messages ?? [{ code: "", message: t('errors.unknown') }] : [{ code: "", message: t('errors.network') }];
          setRequirements((prevRequirements) =>
          {
            const newRequirements = [...prevRequirements];
            const fobj = newRequirements[index].files.find(obj => obj.uuid === uuid);
            if (fobj)
              fobj.errMessages = errMessages;
            return newRequirements;
          });
        })
    }
    else
    {
      const tempId = file.tempId;
      setRequirements((prevRequirements) =>
      {
        const newRequirements = [...prevRequirements];
        const files = newRequirements[index].files.filter(obj => obj.tempId !== tempId);
        newRequirements[index].files = files;
        return newRequirements;
      });
    }
  }

  const handleDownloadFile = (file, index) =>
  {
    const uuid = file.uuid;
    const requirementId = requirements[index].requirementId;
    setRequirements((prevRequirements) =>
    {
      const newRequirements = [...prevRequirements];
      const fobj = newRequirements[index].files.find(obj => obj.uuid === uuid);
      fobj.errMessages = [];
      return newRequirements;
    });
    APIServices.downloadFileDC(applicationID, requirementId, uuid)
      .then((response) =>
      {
        const fileContentType = response.headers['content-type'];
        const filename = response.headers.ulima_filename;
        const fileBinary = new Blob([response.data], { type: fileContentType });
        const fileUrl = URL.createObjectURL(fileBinary);
        const dlink = document.createElement("a");
        dlink.href = fileUrl;
        dlink.download = filename;
        dlink.target = "_blank";
        dlink.style.display = "none";
        document.body.appendChild(dlink);
        dlink.click();
        URL.revokeObjectURL(fileUrl);
        document.body.removeChild(dlink);
      })
      .catch((error) =>
      {
        const errMessages = error.response ? error.response.data?.messages ?? [{ code: "", message: t('errors.unknown') }] : [{ code: "", message: t('errors.network') }];
        setRequirements((prevRequirements) =>
        {
          const newRequirements = [...prevRequirements];
          const fobj = newRequirements[index].files.find(obj => obj.uuid === uuid);
          fobj.errMessages = errMessages;
          return newRequirements;
        });
      })
  }

  const handleDownloadTemplate = (file, index) =>
  {
    const uuid = file.uuid;
    const requirementId = requirements[index].requirementId;
    setRequirements((prevRequirements) =>
    {
      const newRequirements = [...prevRequirements];
      newRequirements[index].errMessages = [];
      return newRequirements;
    });
    APIServices.downloadTemplateDC(applicationID, requirementId, uuid)
      .then((response) =>
      {
        const imageContentType = response.headers['content-type'];
        const imageFilename = response.headers.ulima_filename;
        const imageBinary = new Blob([response.data], { type: imageContentType });
        const imageUrl = URL.createObjectURL(imageBinary);
        const dlink = document.createElement("a");
        dlink.href = imageUrl;
        dlink.download = imageFilename;
        dlink.target = "_blank";
        dlink.style.display = "none";
        document.body.appendChild(dlink);
        dlink.click();
        URL.revokeObjectURL(imageUrl);
        document.body.removeChild(dlink);
        setRequirements((prevRequirements) =>
        {
          const newRequirements = [...prevRequirements];
          const fobj = newRequirements[index].templates.find(obj => obj.uuid === uuid);
          fobj.downloaded = true;
          return newRequirements;
        });
      })
      .catch((error) =>
      {
        console.log(error);
        const errMessages = error.response ? error.response.data?.messages ?? [{ code: "", message: t('errors.unknown') }] : [{ code: "", message: t('errors.network') }];
        setRequirements((prevRequirements) =>
        {
          const newRequirements = [...prevRequirements];
          newRequirements[index].errMessages = errMessages;
          return newRequirements;
        });
      })
  }

  /**********************************************************************/

  const uploadFileNF = (file, tempId, grade) =>
  {
    const setFiles = grade === "fifth" ? setFilesFifthNF : grade === "fourth" ? setFilesFourthNF : setFilesThirdNF;
    const options = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent) =>
      {
        const { loaded, total } = progressEvent;
        const percentCompleted = Math.round((loaded * 100) / total);
        setFiles((prevFiles) => (prevFiles.map((file) =>
        {
          if (file.tempId === tempId)
          {
            file.progress = percentCompleted;
          }
          return file;
        })));
      },
    }
    const applicantSchoolId = getApplicantSchoolID(grade);
    const data = new FormData();
    data.append('file', file);
    data.append('applicationId', applicationID);
    data.append('applicantSchoolId', applicantSchoolId);

    APIServices.uploadFileNF(data, options)
      .then((response) =>
      {
        const data = response.data.data;
        setFiles((prevFiles) => (prevFiles.map((file) =>
        {
          if (file.tempId === tempId)
          {
            file.uploaded = true;
            file.uuid = data.uuid;
            file.sizeText = data.sizeText;
            file.documentState = VERIFICATION_PENDING;
          }
          return file;
        })));
      })
      .catch((error) =>
      {
        const errMessages = error.response ? error.response.data?.messages ?? [{ code: "", message: t('errors.unknown') }] : [{ code: "", message: t('errors.network') }];
        setFiles((prevFiles) => (prevFiles.map((file) =>
        {
          if (file.tempId === tempId)
          {
            file.errMessages = errMessages;
          }
          return file;
        })));
      })
  }

  const isAlreadyUploadedNF = (files, accepted) =>
  {
    return files.some(file =>
      file.name === accepted.name
    );
  }

  const handleSetFilesNF = (accepted, grade) =>
  {
    const setFiles = grade === "fifth" ? setFilesFifthNF : grade === "fourth" ? setFilesFourthNF : setFilesThirdNF;
    const files = grade === "fifth" ? filesFifthNF : grade === "fourth" ? filesFourthNF : filesThirdNF;
    setFiles(accepted.map((file) =>
    {
      if (!file.tempId && !file.uploaded && !file.progress)
      {
        if (!isAlreadyUploadedNF(files, file))
        {
          const uuid = uuidv4();
          uploadFileNF(file, uuid, grade);
          file.tempId = uuid;
          file.uploaded = false;
          file.progress = 0;
          file.canEdit = true;
        }
        else
        {
          const errMessages = [{ code: "", message: t('education.common.file-already-exists-error', { filename: file.name }) }]
          file.already = true;
          file.errMessages = errMessages;
        }
      }
      return file;
    }));
  }

  const handleDeleteFileNF = (file, grade) =>
  {
    const setFiles = grade === "fifth" ? setFilesFifthNF : grade === "fourth" ? setFilesFourthNF : setFilesThirdNF;
    if (file.already)
    {
      setFiles((prevFiles) => (prevFiles.filter((f) => f !== file)));
    }
    else
    {
      const applicantSchoolId = (grade === "fifth" ? fifthGradeSchool.applicantSchoolId : grade === "fourth" ? fourthGradeSchool.applicantSchoolId : thirdGradeSchool.applicantSchoolId);
      APIServices.deleteFileNF({
        applicationId: applicationID,
        applicantSchoolId: applicantSchoolId,
        uuid: file.uuid,
      })
        .then((response) =>
        {
          setFiles((prevFiles) => (prevFiles.filter((f) => f !== file)));
        })
        .catch((error) =>
        {
          const errMessages = error.response ? error.response.data?.messages ?? [{ code: "", message: t('errors.unknown') }] : [{ code: "", message: t('errors.network') }];
          setFiles((prevFiles) => (prevFiles.map((pfile) =>
          {
            if (pfile.uuid === file.uuid)
            {
              pfile.errMessages = errMessages;
            }
            return pfile;
          })));
        })
    }
  }

  const handleDownloadFileNF = (file, grade) =>
  {
    const applicantSchoolId = getApplicantSchoolID(grade);
    const setFiles = grade === "fifth" ? setFilesFifthNF : grade === "fourth" ? setFilesFourthNF : setFilesThirdNF;
    APIServices.downloadFileNF(applicationID, applicantSchoolId, file.uuid)
      .then((response) =>
      {
        const imageContentType = response.headers['content-type'];
        const imageFilename = response.headers.ulima_filename;
        const imageBinary = new Blob([response.data], { type: imageContentType });
        const imageUrl = URL.createObjectURL(imageBinary);
        const dlink = document.createElement("a");
        dlink.href = imageUrl;
        dlink.download = imageFilename;
        dlink.target = "_blank";
        dlink.style.display = "none";
        document.body.appendChild(dlink);
        dlink.click();
        URL.revokeObjectURL(imageUrl);
        document.body.removeChild(dlink);
      })
      .catch((error) =>
      {
        const errMessages = error.response ? error.response.data?.messages ?? [{ code: "", message: t('errors.unknown') }] : [{ code: "", message: t('errors.network') }];
        setFiles((prevFiles) => (prevFiles.map((pfile) =>
        {
          if (pfile.uuid === file.uuid)
          {
            pfile.errMessages = errMessages;
          }
          return pfile;
        })));
      })
  }

  const getApplicantSchoolID = (grade) =>
  {
    if (grade === "fifth")
      return fifthGradeSchool.applicantSchoolId;
    if (grade === "fourth")
      return fourthGradeSchool.applicantSchoolId;
    if (grade === "third")
      return thirdGradeSchool.applicantSchoolId;
  }

  /***************************************************************************/
  const uploadUniversityFile = (file, tempId) =>
  {
    const options = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent) =>
      {
        const { loaded, total } = progressEvent;
        const percentCompleted = Math.round((loaded * 100) / total);
        setUniversityFiles((prevFiles) => (prevFiles.map((file) =>
        {
          if (file.tempId === tempId)
          {
            file.progress = percentCompleted;
          }
          return file;
        })));
      },
    }
    const data = new FormData();
    data.append('file', file);
    data.append('applicationId', applicationID);

    APIServices.uploadUniversityFileNF(data, options)
      .then((response) =>
      {
        const data = response.data.data;
        setUniversityFiles((prevFiles) => (prevFiles.map((file) =>
        {
          if (file.tempId === tempId)
          {
            file.uploaded = true;
            file.uuid = data.uuid;
            file.sizeText = data.sizeText;
            file.documentState = VERIFICATION_PENDING;
          }
          return file;
        })));
      })
      .catch((error) =>
      {
        const errMessages = error.response ? error.response.data?.messages ?? [{ code: "", message: t('errors.unknown') }] : [{ code: "", message: t('errors.network') }];
        setUniversityFiles((prevFiles) => (prevFiles.map((file) =>
        {
          if (file.tempId === tempId)
          {
            file.errMessages = errMessages;
          }
          return file;
        })));
      })
  }

  const isAlreadyUploadedUniversityFile = (accepted) =>
  {
    return universityFiles.some(file =>
      file.name === accepted.name
    );
  }

  const handleSetUniversityFiles = (files) =>
  {
    setUniversityFiles(files.map((file) =>
    {
      if (!file.tempId && !file.uploaded && !file.progress)
      {
        if (!isAlreadyUploadedUniversityFile(file))
        {
          const uuid = uuidv4();
          uploadUniversityFile(file, uuid);
          file.tempId = uuid;
          file.uploaded = false;
          file.progress = 0;
          file.canEdit = true;
        }
        else
        {
          const errMessages = [{ code: "", message: t('education.common.file-already-exists-error', { filename: file.name }) }]
          file.already = true;
          file.errMessages = errMessages;
        }
      }
      return file;
    }));
  }

  const handleDeleteUniversityFile = (file) =>
  {
    if (file.already)
    {
      setUniversityFiles((prevFiles) => (prevFiles.filter((f) => f !== file)));
    }
    else
    {
      APIServices.deleteUniversityFileNF({
        applicationId: applicationID,
        uuid: file.uuid,
      })
        .then((response) =>
        {
          setUniversityFiles((prevFiles) => (prevFiles.filter((f) => f !== file)));
        })
        .catch((error) =>
        {
          const errMessages = error.response ? error.response.data?.messages ?? [{ code: "", message: t('errors.unknown') }] : [{ code: "", message: t('errors.network') }];
          setUniversityFiles((prevFiles) => (prevFiles.map((pfile) =>
          {
            if (pfile.uuid === file.uuid)
            {
              pfile.errMessages = errMessages;
            }
            return pfile;
          })));
        })
    }
  }

  const handleDownloadUniversityFile = (file) =>
  {
    APIServices.downloadUniversityFileNF(applicationID, file.uuid)
      .then((response) =>
      {
        const imageContentType = response.headers['content-type'];
        const imageFilename = response.headers.ulima_filename;
        const imageBinary = new Blob([response.data], { type: imageContentType });
        const imageUrl = URL.createObjectURL(imageBinary);
        const dlink = document.createElement("a");
        dlink.href = imageUrl;
        dlink.download = imageFilename;
        dlink.target = "_blank";
        dlink.style.display = "none";
        document.body.appendChild(dlink);
        dlink.click();
        URL.revokeObjectURL(imageUrl);
        document.body.removeChild(dlink);
      })
      .catch((error) =>
      {
        const errMessages = error.response ? error.response.data?.messages ?? [{ code: "", message: t('errors.unknown') }] : [{ code: "", message: t('errors.network') }];
        setUniversityFiles((prevFiles) => (prevFiles.map((pfile) =>
        {
          if (pfile.uuid === file.uuid)
          {
            pfile.errMessages = errMessages;
          }
          return pfile;
        })));
      })
  }

  const listDisclaimers = () =>
  {
    APIServices.listDisclaimers(applicationID)
      .then((response) =>
      {
        const disclaimers = response.data.data?.list;
        setDisclaimersList(disclaimers);
      })
      .catch((error) =>
      {
        setErrMessages(error.response ? error.response.data?.messages ?? [{ code: "", message: t('errors.unknown') }] : [{ code: "", message: t('errors.network') }])
      })
  }

  useEffect(() =>
  {
    listRequirements();
    listDisclaimers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {
        isLoading ?
          <>
            <Loading />
          </> :
          <>
            <Grid container direction="column" spacing={2}>
              {
                errMessages?.length ?
                  <Grid item xs={12}>
                    <Alert role="alert" severity="error">
                      {
                        errMessages.map((err, index) => (
                          <Typography key={index}> {err.code?.length ? `[${err.code}]` : ""} {err.message}</Typography>
                        ))
                      }
                    </Alert>
                  </Grid>
                  : <></>
              }
              <Grid item xs={12}>
                <Typography variant="h5" component="h5" style={{ fontStyle: 'italic' }}>
                  {t('document-verification.content')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5" component="h4" style={{ textDecoration: 'underline', fontWeight: '500' }}>
                  {t('document-verification.title-documents')}
                </Typography>
              </Grid>
              <Grid
                container
                spacing={2}
                item
                xs={12}
              >
                {
                  requirements.map((requirement, index) =>
                  {
                    return (
                      <Fragment key={index}>
                        {
                          requirement.validationRequired ?
                            <>
                              <Grid
                                container
                                direction="column"
                                spacing={2}
                                item
                                xs={12}
                              >
                                <Grid
                                  item
                                  xs={12}
                                >
                                  {/* Renderiza solo el título */}
                                  <FileUploadManager
                                    canEdit={false}
                                    partial={true}
                                    multiple={false}
                                    onlyAccepted={true}
                                    files={requirement.files}
                                    setFiles={() => { }}
                                    deleteFile={(file) => handleDeleteFile(file, index)}
                                    downloadFile={(file) => handleDownloadFile(file, index)}
                                    downloadTemplate={(file) => handleDownloadTemplate(file, index)}
                                    title={requirement.name}
                                    description={''}
                                    fileType={
                                      {
                                        'application/pdf': [],
                                        'image/jpeg': [],
                                        'application/msword': [],
                                        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
                                      }
                                    }
                                    kind="ver"
                                  />
                                  <Grid container direction="column" spacing={2}>
                                    {
                                      requirement.files?.length && !requirement.valid ? <Grid item xs={12}>
                                        <Alert severity='error' sx={{ marginTop: 1 }}>
                                          <Typography>{t('requested-documents.verification-failed-error')}</Typography>
                                        </Alert>
                                      </Grid> : <></>
                                    }
                                    {
                                      !requirement.files?.length ?
                                        <>
                                          <Grid item xs={12}>
                                            <UploadBox>
                                              <BoxUploadWrapper sx={{ marginTop: 1 }} onClick={e => { canEdit && verifyPicture(e, requirement.requirementId) }}>
                                                <Typography
                                                  sx={{
                                                    marginY: 2
                                                  }}
                                                >
                                                  <CloudUploadOutlined style={{ verticalAlign: 'middle' }} sx={{ marginRight: 2 }} /> {t('document-verification.click-to-upload')}
                                                </Typography>
                                              </BoxUploadWrapper>
                                            </UploadBox>
                                          </Grid>
                                        </> : <></>
                                    }
                                  </Grid>
                                </Grid>
                              </Grid>
                            </> : <>
                              <FileUploadManager
                                canEdit={canEdit && requirement.canUpload}
                                multiple={true}
                                onlyAccepted={true}
                                errMessages={requirement.errMessages ?? []}
                                files={requirement.files}
                                templates={requirement.templates}
                                setFiles={(accepted) => handleSetFiles(index, accepted)}
                                deleteFile={(file) => handleDeleteFile(file, index)}
                                downloadFile={(file) => handleDownloadFile(file, index)}
                                downloadTemplate={(file) => handleDownloadTemplate(file, index)}
                                title={requirement.name}
                                description={''}
                                fileType={
                                  {
                                    'application/pdf': [],
                                    'image/jpeg': [],
                                    'application/msword': [],
                                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
                                  }
                                }
                              />
                              {
                                (canEdit && requirement.isIdScan && requirement.canUpload) ?
                                  <>
                                    <Grid
                                      item
                                      xs={12}
                                    >
                                      <Alert severity="error">
                                        <Typography sx={{ mb: 1 }}>Si proporcionó un documento correcto, pero ingresó mal sus datos, puede corregirlos.</Typography>
                                        <ULButton color="error" onClick={() => { navigate(ROUTES.DOCUMENT_VERIFICATION_STUDENT_PERSONAL_DATA, { state: { applicationDocumentId: requirement?.files[0]?.applicationDocumentId ?? 0 } }) }}>Corregir mis datos</ULButton>
                                      </Alert>
                                    </Grid>
                                  </> : <></>
                              }
                            </>
                        }
                        <Divider />
                      </Fragment>
                    )
                  })
                }
              </Grid>
              {
                fifthGradeSchool.notFound ?
                  <>
                    <Grid item xs={12}></Grid>
                    <Grid item xs={12}></Grid>
                    <Grid item xs={12}>
                      <Typography variant="h5" component="h4">
                        {fifthGradeSchool.sameSchool ? t('document-verification.title-fifth-grade-school-not-found') : t('document-verification.title-fifth-grade-school')}
                      </Typography>
                    </Grid>
                    <Grid
                      container
                      spacing={2}
                      item
                      xs={12}
                    >
                      <FileUploadManager
                        canEdit={canUploadFifthNF}
                        multiple={true}
                        files={filesFifthNF}
                        setFiles={(file) => handleSetFilesNF(file, "fifth")}
                        deleteFile={(file) => handleDeleteFileNF(file, "fifth")}
                        downloadFile={(file) => handleDownloadFileNF(file, "fifth")}
                        title={''}
                        description={''}
                        fileType={
                          {
                            'application/pdf': [],
                            'image/jpeg': [],
                            'application/msword': [],
                            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
                          }
                        }
                      />
                    </Grid>
                  </> : <></>
              }
              {
                !fifthGradeSchool.sameSchool && !fourthGradeSchool.sameSchool && fourthGradeSchool.notFound ?
                  <>
                    <Grid item xs={12}></Grid>
                    <Grid item xs={12}>
                      <Typography variant="h5" component="h4">
                        {fifthGradeSchool.sameSchool ? t('document-verification.title-fourth-grade-school-not-found') : t('document-verification.title-fourth-grade-school')}
                      </Typography>
                    </Grid>
                    <Grid
                      container
                      spacing={2}
                      item
                      xs={12}
                    >
                      <FileUploadManager
                        canEdit={canUploadFourthNF}
                        multiple={true}
                        files={filesFourthNF}
                        setFiles={(file) => handleSetFilesNF(file, "fourth")}
                        deleteFile={(file) => handleDeleteFileNF(file, "fourth")}
                        downloadFile={(file) => handleDownloadFileNF(file, "fourth")}
                        title={''}
                        description={''}
                        fileType={
                          {
                            'application/pdf': [],
                            'image/jpeg': [],
                            'application/msword': [],
                            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
                          }
                        }
                      />
                    </Grid>
                  </> : <></>
              }
              {
                !fifthGradeSchool.sameSchool && !fourthGradeSchool.sameSchool && thirdGradeSchool.notFound ?
                  <>
                    <Grid item xs={12}></Grid>
                    <Grid item xs={12}>
                      <Typography variant="h5" component="h4">
                        {t('document-verification.title-third-grade-school')}
                      </Typography>
                    </Grid>
                    <Grid
                      container
                      spacing={2}
                      item
                      xs={12}
                    >
                      <FileUploadManager
                        canEdit={canUploadThirdNF}
                        multiple={true}
                        files={filesThirdNF}
                        setFiles={(file) => handleSetFilesNF(file, "third")}
                        deleteFile={(file) => handleDeleteFileNF(file, "third")}
                        downloadFile={(file) => handleDownloadFileNF(file, "third")}
                        title={''}
                        description={''}
                        fileType={
                          {
                            'application/pdf': [],
                            'image/jpeg': [],
                            'application/msword': [],
                            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
                          }
                        }
                      />
                    </Grid>  </> : <></>
              }

              {
                universityData.notFound ?
                  <>
                    <Grid item xs={12}></Grid>
                    <Grid item xs={12}>
                      <Typography variant="h5" component="h4">
                        {t('document-verification.title-university')}
                      </Typography>
                    </Grid>
                    <Grid
                      container
                      spacing={2}
                      item
                      xs={12}
                    >
                      <FileUploadManager
                        canEdit={canUploadUniversity}
                        multiple={true}
                        files={universityFiles}
                        setFiles={handleSetUniversityFiles}
                        deleteFile={handleDeleteUniversityFile}
                        downloadFile={handleDownloadUniversityFile}
                        title={''}
                        description={''}
                        fileType={
                          {
                            'application/pdf': [],
                            'image/jpeg': [],
                            'application/msword': [],
                            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
                          }
                        }
                      />
                    </Grid>
                  </> : <></>
              }


              <Grid item xs={12}>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5" component="h5" style={{ fontWeight: 400, fontSize: '12px' }}>
                  {t('document-verification.advice')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel disabled={!canEdit && !(
                  requirements?.every(requirement => requirement.files.length > 0 && requirement.files?.every(file => file.documentState === VERIFICATION_VALID)) &&
                  filesFifthNF?.every(file => file.documentState === VERIFICATION_VALID) &&
                  filesFourthNF?.every(file => file.documentState === VERIFICATION_VALID) &&
                  filesThirdNF?.every(file => file.documentState === VERIFICATION_VALID) &&
                  universityFiles?.every(file => file.documentState === VERIFICATION_VALID)
                )} control={<Checkbox onChange={handleTermsAndConditionsChange} checked={documentsTermsAndConditions} />} label={`${t('requested-documents.terms-conditions-p1')}${t('requested-documents.terms-conditions-p2')}${t('requested-documents.terms-conditions-p3')}`} />
              </Grid>
              <Grid item xs={12}>
                {
                  (
                    requirements?.every(requirement => requirement.files.length > 0 && requirement.files?.every(file => file.documentState === VERIFICATION_VALID)) &&
                    filesFifthNF?.every(file => file.documentState === VERIFICATION_VALID) &&
                    filesFourthNF?.every(file => file.documentState === VERIFICATION_VALID) &&
                    filesThirdNF?.every(file => file.documentState === VERIFICATION_VALID) &&
                    universityFiles?.every(file => file.documentState === VERIFICATION_VALID)
                  ) ?
                    <>
                      <ULButton disabled={!documentsTermsAndConditions} working={working} onClick={handleContinue}>
                        {t('buttons.continue')}
                      </ULButton>
                    </> : <>
                      <ULButton disabled={
                        !documentsTermsAndConditions ||
                        !requirements?.every(requirement => requirement.files.length > 0 && requirement.files?.every(file => file.documentState === VERIFICATION_VALID || file.documentState === VERIFICATION_PENDING)) ||
                        !filesFifthNF?.every(file => file.documentState === VERIFICATION_VALID || file.documentState === VERIFICATION_PENDING) ||
                        !filesFourthNF?.every(file => file.documentState === VERIFICATION_VALID || file.documentState === VERIFICATION_PENDING) ||
                        !filesThirdNF?.every(file => file.documentState === VERIFICATION_VALID || file.documentState === VERIFICATION_PENDING) ||
                        !universityFiles?.every(file => file.documentState === VERIFICATION_VALID || file.documentState === VERIFICATION_PENDING) ||
                        !canEdit
                      } working={working} onClick={handleFinishVerification}>
                        {t('buttons.re-submit-fixed')}
                      </ULButton>
                    </>
                }
              </Grid>

              <Grid item xs={12}>
                <ULButton variant="outlined" goBack={true} onClick={() => navigate(ROUTES.PAYMENTS)}>
                  {t('buttons.back')}
                </ULButton>
              </Grid>

              <Grid item xs={12}>
                <ULButton
                  variant="outlined"
                  color="secondary"
                  onClick={() => { navigate(ROUTES.APPLICATION_SUSPEND, { state: { step: 5 } }) }}
                >
                  {t('buttons.continue-later')}
                </ULButton>
              </Grid>

              <Dialog
                open={openAgreementModal}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCloseAgreementModal}
              >
                <DialogTitle>{t('requested-documents.complementary-provisions-title')}</DialogTitle>
                <DialogContent style={{ fontFamily: 'Roboto' }}>
                  <p>
                    {t('requested-documents.complementary-provisions-p1')}
                  </p>
                  <p>
                    {t('requested-documents.complementary-provisions-p2')}
                  </p>
                  <ul>
                    <li>{t('requested-documents.complementary-provisions-p3-li')}</li>
                    <li>{t('requested-documents.complementary-provisions-p4-li')}</li>
                  </ul>
                  <p>
                    {t('requested-documents.complementary-provisions-p5')}
                  </p>
                  <ul>
                    {
                      disclaimersList?.map((disclaimer) => (<Fragment>
                        <li><Link to={`${ROUTES.DISCLAIMERS_BASE}/${disclaimer.uuid}`} target='_blank' style={{ color: '#ff5117' }}>{disclaimer.name}</Link></li>
                      </Fragment>))
                    }
                  </ul>
                  <p>
                    {t('requested-documents.complementary-provisions-p9')}
                  </p>
                  <p>
                    {t('requested-documents.complementary-provisions-p10')}
                  </p>
                  <p>
                    {t('requested-documents.complementary-provisions-p11')}
                  </p>

                </DialogContent>
                <DialogActions>
                  <ULButton variant="outlined" onClick={handleDisagreeTerms}>{t('requested-documents.complementary-provisions-disagree-btn')}</ULButton>
                  <ULButton onClick={handleAgreeTerms}>{t('requested-documents.complementary-provisions-agree-btn')}</ULButton>
                </DialogActions>
              </Dialog>
            </Grid>
          </>
      }
    </>
  );
}