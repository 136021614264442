import { useState } from "react";
import { createContext } from "react";

const PersonalInformationContext = createContext();
export default PersonalInformationContext;

export const PersonalInformationContextProvider = ({ children }) =>
{
    const [documentNumber, setDocumentNumber] = useState("");
    const [documentType, setDocumentType] = useState(0);
    const [name, setName] = useState('')
    const [firstLastName, setFirstLastName] = useState('')
    const [secondLastName, setSecondLastName] = useState('')
    const [cellphone, setCellphone] = useState('')
    const [email, setEmail] = useState('')
    const [emailValidation, setEmailValidation] = useState('')
    const [readTerms, setReadTerms] = useState(false)
    const [acceptTerms, setAcceptTerms] = useState(false)
    const [hasDisability, setHasDisability] = useState('');
    const [hasConadis, setHasConadis] = useState('');
    const [disabilityDescription, setDisabilityDescription] = useState('');
    const [conadisDocument, setConadisDocument] = useState('');

    const resetPersonalInformationContext = () =>
    {
        setDocumentNumber("");
        setDocumentType(0);
        setName('');
        setFirstLastName('');
        setSecondLastName('');
        setCellphone('');
        setEmail('');
        setEmailValidation('');
        setReadTerms(false);
        setAcceptTerms(false);
        setHasDisability('no');
        setHasConadis('no');
        setDisabilityDescription('');
        setConadisDocument('');
    }

    return (
        <PersonalInformationContext.Provider value={{
            documentNumber: documentNumber,
            setDocumentNumber: setDocumentNumber,
            documentType: documentType,
            setDocumentType: setDocumentType,
            name: name,
            setName: setName,
            firstLastName: firstLastName,
            setFirstLastName: setFirstLastName,
            secondLastName: secondLastName,
            setSecondLastName: setSecondLastName,
            cellphone: cellphone,
            setCellphone: setCellphone,
            email: email,
            setEmail: setEmail,
            emailValidation: emailValidation,
            setEmailValidation: setEmailValidation,
            readTerms: readTerms,
            setReadTerms: setReadTerms,
            acceptTerms: acceptTerms,
            setAcceptTerms: setAcceptTerms,
            hasDisability: hasDisability,
            setHasDisability: setHasDisability,
            hasConadis: hasConadis,
            setHasConadis: setHasConadis,
            disabilityDescription: disabilityDescription,
            setDisabilityDescription: setDisabilityDescription,
            conadisDocument: conadisDocument,
            setConadisDocument: setConadisDocument,
            resetPersonalInformationContext: resetPersonalInformationContext,
        }}>
            {children}
        </PersonalInformationContext.Provider>
    )
}