import * as APIServices from "../../services/rest/APIServices";
import Loading from '../../components/Loading';
import { useEffect, useState } from "react";
import { Alert, Grid, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import './Disclaimers.css';
import { SCROLLABLE_AREA_NAME } from "../../utils/CommonFunctions";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useTranslation } from "react-i18next";

export default function Disclaimers ()
{
    const [isLoading, setIsLoading] = useState(true);
    const [errMessages, setErrMessages] = useState([]);
    const { uuid } = useParams();
    const { t } = useTranslation();

    const handleDownloadFile = () =>
    {
        setErrMessages([]);
        setIsLoading(true);
        APIServices.downloadDisclaimerFile(uuid)
            .then((response) =>
            {
                const fileContentType = response.headers['content-type'];
                const filename = response.headers.ulima_filename;
                const fileBinary = new Blob([response.data], { type: fileContentType });
                const fileUrl = URL.createObjectURL(fileBinary);
                const dlink = document.createElement("a");
                dlink.href = fileUrl;
                dlink.download = filename;
                dlink.target = "_blank";
                dlink.style.display = "none";
                document.body.appendChild(dlink);
                dlink.click();
                URL.revokeObjectURL(fileUrl);
                document.body.removeChild(dlink);
            })
            .catch((error) =>
            {
                const errMessages = error.response ? error.response.data?.messages ?? [{ code: "", message: t('errors.unknown') }] : [{ code: "", message: t('errors.network') }];
                setErrMessages(errMessages);
            })
            .finally(() =>
            {
                setIsLoading(false);
            })
    }

    useEffect(() =>
    {
        handleDownloadFile();
        const mobileContainer = document.getElementById(SCROLLABLE_AREA_NAME);
        mobileContainer.classList.remove("mobile-view");
        mobileContainer.classList.add("custom-mobile-view");
        return () =>
        {
            const mobileContainer = document.getElementById(SCROLLABLE_AREA_NAME);
            mobileContainer.classList.add("mobile-view");
            mobileContainer.classList.remove("custom-mobile-view");
        }
        // return (mobileContainer.classList.add("mobile-view"));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const closeWindow = () => {
        window.close();
    }

    return (
        <>
            {
                isLoading ?
                    <>
                        <Loading />
                    </> :
                    <>
                        <Grid container direction="column" spacing={2}>
                            {
                                errMessages?.length ?
                                    <Grid item xs={12}>
                                        <Alert role="alert" severity="error">
                                            {
                                                errMessages.map((err, index) => (
                                                    <Typography key={index}> {err.code?.length ? `[${err.code}]` : ""} {err.message}</Typography>
                                                ))
                                            }
                                        </Alert>
                                    </Grid>
                                    : <>
                                        <Grid item xs={12}>
                                            <CheckCircleOutlineIcon sx={{mt: 5, display: 'block', mx: 'auto', fontSize: '4rem'}} />
                                            <Typography sx={{ mt: 5, textAlign: 'center' }} component="h2" variant="h3">{t('disclaimers.download-success')}</Typography>
                                            <Typography sx={{ mt: 5, textAlign: 'center' }} component="p" variant="body1" color="secondary">{t('disclaimers.can-close-window')}</Typography>
                                        </Grid>
                                    </>
                            }
                        </Grid>
                    </>
            }
        </>
    );
}