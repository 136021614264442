
import { Alert, Grid, Typography } from "@mui/material";
import { useState, useRef, useContext } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as ROUTES from "../../routes/routes";
import { useTranslation } from "react-i18next";
import * as APIServices from "../../services/rest/APIServices";
import UserAvatarEditor from '../../components/UserAvatarEditor';
import axios from "axios";
import PersonalInformationContext from "../../context/PersonalInformationContext";
import ApplicationContext from "../../context/ApplicationContext";
import ULButton from "../../components/ULButton";
import { SCROLLABLE_AREA_NAME } from "../../utils/CommonFunctions";
import { useEffect } from "react";
import Loading from "../../components/Loading";

const VERIFICATOR_USERNAME = process.env.REACT_APP_VERIFICATOR_USERNAME;
const VERIFICATOR_ACCESS_KEY = process.env.REACT_APP_VERIFICATOR_ACCESS_KEY;
const VERIFICATOR_SECRET_KEY = process.env.REACT_APP_VERIFICATOR_SECRET_KEY;
const VERIFICATOR_RULE_ID = process.env.REACT_APP_VERIFICATOR_RULE_ID;
const VERIFICATOR_ENDPOINT = process.env.REACT_APP_VERIFICATOR_ENDPOINT;
const VERIFICATOR_AUTH_API = process.env.REACT_APP_VERIFICATOR_AUTH_API;
const VERIFICATOR_SAVE_B64_API = process.env.REACT_APP_VERIFICATOR_SAVE_B64_API;
const VERIFICATOR_VERIFY_API = process.env.REACT_APP_VERIFICATOR_VERIFY_API;
const ULIMA_FOTO_CARNE_CODE = process.env.REACT_APP_ULIMA_FOTO_CARNE_CODE;
const ULIMA_SOURCE_SYNOLOGY = process.env.REACT_APP_ULIMA_SOURCE_SYNOLOGY;
const ULIMA_URL_SYNOLOGY = process.env.REACT_APP_ULIMA_URL_SYNOLOGY;
const ULIMA_USR_SYNOLOGY = process.env.REACT_APP_ULIMA_USR_SYNOLOGY;
const CarnePhoto = (props) =>
{

    const { t } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const requirementId = parseInt(searchParams.get('requirementId') ?? '0');
    const from = searchParams.get('from') ?? 'requirements';
    const [errMessages, setErrMessages] = useState([]);
    const [choosedPhoto, setChoosedPhoto] = useState(false);
    const avatarEditorRef = useRef();
    const [uploading, setUploading] = useState(false);
    const { OMR } = useContext(ApplicationContext);
    const { applicationID } = useContext(ApplicationContext);
    const { documentNumber, setDocumentNumber,
        documentType, setDocumentType,
        name, setName,
        firstLastName, setFirstLastName,
        secondLastName, setSecondLastName,
        cellphone, setCellphone,
        email, setEmail } = useContext(PersonalInformationContext);
    const [isLoading, setIsLoading] = useState(false);
    const [verificationStatus, setVerificationStatus] = useState('');
    const handlePhotoChange = (status) =>
    {
        setChoosedPhoto(status);
    }

    const fetchData = async () =>
    {
        if (!applicationID) return;
        setIsLoading(true);
        await APIServices.fetchApplicantInfo(applicationID)
            .then((response) =>
            {
                const data = response.data.data;
                setName(data.name ?? '');
                setFirstLastName(data.firstSurname ?? '');
                setSecondLastName(data.secondSurname ?? '');
                setCellphone(data.cellPhone ?? '');
                setEmail(data.email ?? '');
            })
            .catch((error) =>
            {
                // No se pudo obtener la info (aún no ha sido registrada)
            })
            .finally(() =>
            {
                setIsLoading(false);
            })
        await APIServices.fetchApplicantDocument(applicationID)
            .then((response) =>
            {
                const data = response.data.data;
                setDocumentNumber(data.documentNumber);
                setDocumentType(data.documentTypeId);
            })
            .catch((error) =>
            {
                // No se pudo obtener la info (aún no ha sido registrada)
            })
            .finally(() =>
            {
                setIsLoading(false);
            })
    }

    useEffect(() =>
    {
        const callServices = async () =>
        {
            await fetchData();
        }
        callServices();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleUpload = async (e) =>
    {
        const file = await avatarEditorRef.current?.getPicture() ?? null;
        if (!file) return;
        if (!requirementId)
        {
            setErrMessages([{ code: "", message: t('picture-verificator.invalid-invocation-error') }]);
            const scrollableArea = document.getElementById(SCROLLABLE_AREA_NAME);
            scrollableArea.scrollTo({ top: 0, behavior: 'smooth' });
            window.scrollTo({ top: 0, behavior: 'smooth' });
            return;
        }
        setErrMessages([]);
        setUploading(true);
        setVerificationStatus(t('picture-verificator.uploading-picture-notice'));
        const options = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }
        const data = new FormData();
        data.append('file', file.image);
        data.append('applicationId', applicationID);
        data.append('requirementId', requirementId);

        APIServices.uploadFileDC(data, options)
            .then((responseUFDC) =>
            {
                const upPhotoData = responseUFDC.data.data;
                const data = {
                    base64: file.base64,
                    base64Original: file.base64Original,
                    fileName: file.name,
                    name: name,
                    firstSurname: firstLastName,
                    secondSurname: secondLastName,
                    documentType: documentType,
                    documentNumber: documentNumber,
                    ruleId: VERIFICATOR_RULE_ID,
                    username: VERIFICATOR_USERNAME,
                    imageCode: `${OMR}-${ULIMA_FOTO_CARNE_CODE}`,
                    sourceCode: ULIMA_SOURCE_SYNOLOGY,
                    urlObject: ULIMA_URL_SYNOLOGY,
                    userCode: ULIMA_USR_SYNOLOGY,
                };
                // Datos opcionales
                cellphone.length && (data.phoneNumber = cellphone);
                email.length && (data.email = email);

                setVerificationStatus(t('picture-verificator.authenticating-request-notice'));
                axios.post(`${VERIFICATOR_ENDPOINT}${VERIFICATOR_AUTH_API}`, {
                    accessKey: VERIFICATOR_ACCESS_KEY,
                    secretKey: VERIFICATOR_SECRET_KEY,
                })
                    .then((response) =>
                    {
                        const access_token = response.data.data.token;
                        setVerificationStatus(t('picture-verificator.picture-preparing-notice'));
                        axios.post(`${VERIFICATOR_ENDPOINT}${VERIFICATOR_SAVE_B64_API}`, data, {
                            timeout: 180000,
                            headers: {
                                Authorization: 'Bearer ' + access_token,
                                'Content-Type': 'application/json',
                                accept: 'application/json',
                            },
                        })
                            .then((response) =>
                            {
                                setVerificationStatus(t('picture-verificator.picture-verifying-notice'));
                                axios.post(`${VERIFICATOR_ENDPOINT}${VERIFICATOR_VERIFY_API}`, {
                                    verificationId: response.data.data.verificationId,
                                    uuid: response.data.data.uuid,
                                    username: VERIFICATOR_USERNAME,
                                }, {
                                    headers: {
                                        Authorization: 'Bearer ' + access_token,
                                        'Content-Type': 'application/json',
                                        accept: 'application/json',
                                    }
                                })
                                    .then((responseVV) =>
                                    {
                                        const verPhotoData = responseVV.data.data;
                                        APIServices.updateRequirementDC(applicationID, requirementId, upPhotoData.uuid, verPhotoData.valid)
                                            .then(() =>
                                            {
                                                navigate(`${ROUTES.DOCUMENTS_CARNE_PHOTO_VALIDATION_RESULTS_PART}/${response.data.data.verificationId}?requirementId=${requirementId}&from=${from}`);
                                            })
                                            .catch((error) =>
                                            {
                                                setErrMessages(
                                                    error.response ? error.response.data?.messages ?? [{ code: "", message: t('errors.unknown') }] : [{ code: "", message: t('errors.network') }]
                                                )
                                                setVerificationStatus('');
                                                setUploading(false);
                                                const scrollableArea = document.getElementById(SCROLLABLE_AREA_NAME);
                                                scrollableArea.scrollTo({ top: 0, behavior: 'smooth' });
                                                window.scrollTo({ top: 0, behavior: 'smooth' });
                                            })
                                    })
                                    .catch((error) =>
                                    {
                                        setErrMessages(
                                            error.response ? error.response.data?.messages ?? [{ code: "", message: t('errors.unknown') }] : [{ code: "", message: t('errors.network') }]
                                        )
                                        setVerificationStatus('');
                                        setUploading(false);
                                        const scrollableArea = document.getElementById(SCROLLABLE_AREA_NAME);
                                        scrollableArea.scrollTo({ top: 0, behavior: 'smooth' });
                                        window.scrollTo({ top: 0, behavior: 'smooth' });
                                    })
                            })
                            .catch((error) =>
                            {
                                setErrMessages(
                                    error.response ? error.response.data?.messages ?? [{ code: "", message: t('errors.unknown') }] : [{ code: "", message: t('errors.network') }]
                                );
                                setVerificationStatus('');
                                setUploading(false);
                                const scrollableArea = document.getElementById(SCROLLABLE_AREA_NAME);
                                scrollableArea.scrollTo({ top: 0, behavior: 'smooth' });
                                window.scrollTo({ top: 0, behavior: 'smooth' });
                            })
                    })
                    .catch((error) =>
                    {
                        setErrMessages(
                            error.response ? error.response.data?.messages ?? [{ code: "", message: t('errors.unknown') }] : [{ code: "", message: t('errors.network') }]
                        );
                        setVerificationStatus('');
                        setUploading(false);
                        const scrollableArea = document.getElementById(SCROLLABLE_AREA_NAME);
                        scrollableArea.scrollTo({ top: 0, behavior: 'smooth' });
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                    })
            })
            .catch((error) =>
            {
                setErrMessages(
                    error.response ? error.response.data?.messages ?? [{ code: "", message: t('errors.unknown') }] : [{ code: "", message: t('errors.network') }]
                );
                setVerificationStatus('');
                setUploading(false);
                const scrollableArea = document.getElementById(SCROLLABLE_AREA_NAME);
                scrollableArea.scrollTo({ top: 0, behavior: 'smooth' });
                window.scrollTo({ top: 0, behavior: 'smooth' });
            });
    }

    return (
        <>
            {
                isLoading ?
                    <>
                        <Loading />
                    </> :
                    <>
                        <Grid container direction="column" spacing={2}>
                            {
                                errMessages?.length ?
                                    <Grid item xs={12}>
                                        <Alert role="alert" severity="error">
                                            {
                                                errMessages.map((err, index) => (
                                                    <Typography key={index}> {err.code?.length ? `[${err.code}]` : ""} {err.message}</Typography>
                                                ))
                                            }
                                        </Alert>
                                    </Grid>
                                    : <></>
                            }
                            <Grid item xs={12}>
                                <Typography variant="h5" component="h4" style={{ textDecoration: 'underline', fontWeight: '500' }}>
                                    {t('carne-photo.title')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h5" component="h5" style={{ fontStyle: 'italic' }}>
                                    {t('carne-photo.subtitle')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h5" component="h5">
                                    - {t('carne-photo.description')}
                                </Typography>
                                <Typography variant="body2" component="p">
                                    {t('carne-photo.image-description')}
                                </Typography>
                                <Typography variant="body2" component="p">
                                    <span style={{ fontWeight: 500 }}>- {t('carne-photo.format-part1')}&nbsp;</span>{t('carne-photo.format-part2')}
                                </Typography>
                                <Typography variant="body2" component="p">

                                </Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <UserAvatarEditor ref={avatarEditorRef} callBack={handlePhotoChange} />
                            </Grid>
                            <Grid item xs={12} >
                                <ULButton disabled={!choosedPhoto || uploading}
                                    working={uploading}
                                    onClick={handleUpload}>
                                    {t('buttons.validate-photo')}
                                </ULButton>
                            </Grid>
                            {
                                verificationStatus?.length ?
                                    <>
                                        <Grid item xs={12}>
                                            <Typography sx={{ textAlign: 'center' }} variant="body2" component="p">{verificationStatus}</Typography>
                                        </Grid>
                                    </> : <></>
                            }
                            <Grid item xs={12}>
                                <ULButton variant="outlined" color="secondary" onClick={() => navigate(ROUTES.APPLICATION_SUSPEND, { state: { step: 3 } })}>
                                    {t('buttons.continue-later')}
                                </ULButton>
                            </Grid>
                        </Grid>
                    </>
            }
        </>
    )
}

export default CarnePhoto;