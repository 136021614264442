
import { Alert, Grid, List, ListItem, ListItemText, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as ROUTES from "../../routes/routes";
import { useTranslation } from "react-i18next";
import * as APIServices from "../../services/rest/APIServices";
import ApplicationContext from "../../context/ApplicationContext";
import { canUserEditInformation, SCROLLABLE_AREA_NAME } from "../../utils/CommonFunctions";
import { Edit } from "@mui/icons-material";
import Loading from '../../components/Loading';
import ULButton from "../../components/ULButton";

const FOREIGN_SCHOOL_ID = 2;

const ApplicationSummary = (props) =>
{

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { applicationID, setCorrecting } = useContext(ApplicationContext);
    const [isLoading, setIsLoading] = useState(false);
    const [errMessages, setErrMessages] = useState([]);
    const [userData, setUserData] = useState({});
    const [working, setWorking] = useState(false);
    const [canEdit, setCanEdit] = useState(true);

    useEffect(() =>
    {
        setCorrecting(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fetchData = async () =>
    {
        if (!applicationID) return;
        setIsLoading(true);
        await APIServices.fetchApplicationSummaryInfo(applicationID)
            .then((response) =>
            {
                const data = response.data.data;
                if (data)
                {
                    setUserData(data);
                }
                const status = response.data.data?.status;
                setCanEdit(canUserEditInformation(status));
            })
            .catch((error) =>
            {
                // No se pudo obtener la info (aún no ha sido registrada)
            })
            .finally(() =>
            {
                setIsLoading(false);
            })
    }

    useEffect(() =>
    {
        const callServices = async () =>
        {
            await fetchData();
        }
        callServices();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const editEducation = () =>
    {
        setCorrecting(true);
        navigate(ROUTES.EDUCATION_FIFTH_SCHOOL_TYPE);
    }

    const editPersonalInformation = () =>
    {
        setCorrecting(true);
        navigate(ROUTES.STUDENT_IDENTIFICATION);
    }

    const editCareersAndModalities = () =>
    {
        setCorrecting(true);
        navigate(ROUTES.MODALITY_AND_CARREERS_CHOOSE_MODALITY);
    }

    const confirmProvidedInformation = (e) =>
    {
        setWorking(true);
        setErrMessages([]);
        APIServices.confirmApplicantData(applicationID)
            .then((response) =>
            {
                navigate(ROUTES.DOCUMENTS_REQUESTED_DOCUMENTS);
            })
            .catch((error) =>
            {
                setErrMessages(error.response ? error.response.data?.messages ?? [{ code: "", message: t('errors.unknown') }] : [{ code: "", message: t('errors.network') }])
                const scrollableArea = document.getElementById(SCROLLABLE_AREA_NAME);
                scrollableArea.scrollTo({ top: 0, behavior: 'smooth' });
                window.scrollTo({ top: 0, behavior: 'smooth' });
            })
            .finally(() =>
            {
                setWorking(false);
            })
    }


    return (
        <>
            {
                isLoading ?
                    <>
                        <Loading />
                    </> :
                    <>
                        <Grid container direction="column" spacing={2}>
                            {
                                errMessages?.length ?
                                    <Grid item xs={12}>
                                        <Alert role="alert" severity="error">
                                            {
                                                errMessages.map((err, index) => (
                                                    <Typography key={index}> {err.code?.length ? `[${err.code}]` : ""} {err.message}</Typography>
                                                ))
                                            }
                                        </Alert>
                                    </Grid>
                                    : <></>
                            }
                            {
                                !canEdit ?
                                    <>
                                        <Grid item xs={12}>
                                            <Alert role="alert" severity="warning">
                                                <Typography>{t('common.already-confirmed')}</Typography>
                                            </Alert>
                                        </Grid>
                                    </> : <></>
                            }
                            <Grid item xs={12}>
                                <Typography variant="subtitle2" component="h2" style={{ textDecoration: 'underline', fontWeight: '500' }}>
                                    {t('summary.title')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <List sx={{ pt: 0 }}>
                                    <ListItem disableGutters sx={{ pt: 0 }}>
                                        <ListItemText primary={
                                            <Typography variant="subtitle2" component="h3">
                                                {t('summary.call')}
                                            </Typography>
                                        } />
                                    </ListItem>
                                    <ListItem disableGutters>
                                        <div >
                                            <Typography variant="subtitle2" component="h4">
                                                {userData?.callName}
                                            </Typography>
                                            <Typography variant="subtitle2" component="p">
                                                <Typography variant="body1" component="span">
                                                    {userData?.callDescription}
                                                </Typography>
                                            </Typography>
                                        </div>
                                    </ListItem>

                                    <ListItem
                                        disableGutters
                                        secondaryAction={
                                            <ULButton disabled={!canEdit} variant="text" endIcon={<Edit />} onClick={editPersonalInformation}>
                                                {t('summary.edit-btn')}
                                            </ULButton>
                                        }
                                    >
                                        <ListItemText primary={
                                            <Typography variant="subtitle2" component="h3">
                                                {t('summary.personal-data')}
                                            </Typography>
                                        } />
                                    </ListItem>
                                    <ListItem disableGutters>
                                        <div >
                                            <Typography variant="subtitle2" component="p">
                                                {t('summary.names')}&nbsp;
                                                <Typography variant="body1" component="span">
                                                    {userData?.name}
                                                </Typography>
                                            </Typography>
                                            <Typography variant="subtitle2" component="p">
                                                {t('summary.first-lastname')}&nbsp;
                                                <Typography variant="body1" component="span">
                                                    {userData?.firstSurname}
                                                </Typography>
                                            </Typography>
                                            <Typography variant="subtitle2" component="p">
                                                {t('summary.second-lastname')}&nbsp;
                                                <Typography variant="body1" component="span">
                                                    {userData?.secondSurname}
                                                </Typography>
                                            </Typography>
                                            <Typography variant="subtitle2" component="p">
                                                {t('summary.id-type')}&nbsp;
                                                <Typography variant="body1" component="span">
                                                    {userData?.documentTypeName}
                                                </Typography>
                                            </Typography>
                                            <Typography variant="subtitle2" component="p">
                                                {t('summary.id-number')}&nbsp;
                                                <Typography variant="body1" component="span">
                                                    {userData?.documentNumber}
                                                </Typography>
                                            </Typography>
                                        </div>
                                    </ListItem>

                                    <ListItem
                                        disableGutters
                                        secondaryAction={
                                            <ULButton disabled={!canEdit} variant="text" endIcon={<Edit />} onClick={editEducation}>
                                                {t('summary.edit-btn')}
                                            </ULButton>
                                        }
                                    >
                                        <ListItemText primary={
                                            <Typography variant="subtitle2" component="h3">
                                                {t('summary.education')}
                                            </Typography>
                                        } />
                                    </ListItem>
                                    <ListItem disableGutters>
                                        <div style={{ width: '100%' }}>
                                            <Typography variant="subtitle2" component="p">
                                                {t('summary.fifth-school')}&nbsp;
                                                <Typography variant="body1" component="span">
                                                    {userData?.fifthGradeSchool?.schoolType === FOREIGN_SCHOOL_ID ? t('summary.foreign-school') : userData?.fifthGradeSchool?.notFound ? t('summary.school-not-found') : userData?.fifthGradeSchool?.schoolName}
                                                </Typography>
                                            </Typography>
                                            <Typography variant="subtitle2" component="p">
                                                {t('summary.year')}&nbsp;
                                                <Typography variant="body1" component="span">
                                                    {userData?.fifthGradeSchool?.schoolYear}
                                                </Typography>
                                            </Typography>
                                            <hr />
                                            <Typography variant="subtitle2" component="p">
                                                {t('summary.fourth-school')}&nbsp;
                                                <Typography variant="body1" component="span">
                                                    {userData?.fourthGradeSchool?.schoolType === FOREIGN_SCHOOL_ID ? t('summary.foreign-school') : userData?.fourthGradeSchool?.notFound ? t('summary.school-not-found') : userData?.fourthGradeSchool?.schoolName}
                                                </Typography>
                                            </Typography>
                                            <Typography variant="subtitle2" component="p">
                                                {t('summary.year')}&nbsp;
                                                <Typography variant="body1" component="span">
                                                    {userData?.fourthGradeSchool?.schoolYear}
                                                </Typography>
                                            </Typography>
                                            <hr />
                                            <Typography variant="subtitle2" component="p">
                                                {t('summary.third-school')}&nbsp;
                                                <Typography variant="body1" component="span">
                                                    {userData?.thirdGradeSchool?.schoolType === FOREIGN_SCHOOL_ID ? t('summary.foreign-school') : userData?.thirdGradeSchool?.notFound ? t('summary.school-not-found') : userData?.thirdGradeSchool?.schoolName}
                                                </Typography>
                                            </Typography>
                                            <Typography variant="subtitle2" component="p">
                                                {t('summary.year')}&nbsp;
                                                <Typography variant="body1" component="span">
                                                    {userData?.thirdGradeSchool?.schoolYear}
                                                </Typography>
                                            </Typography>
                                        </div>
                                    </ListItem>

                                    <ListItem
                                        disableGutters
                                        secondaryAction={
                                            <ULButton disabled={!canEdit} variant="text" endIcon={<Edit />} onClick={editCareersAndModalities}>
                                                {t('summary.edit-btn')}
                                            </ULButton>
                                        }
                                    >
                                        <ListItemText primary={
                                            <Typography variant="subtitle2" component="h3">
                                                {t('summary.modalities-and-careers')}
                                            </Typography>
                                        } />
                                    </ListItem>
                                    <ListItem disableGutters>
                                        <div style={{ width: '100%' }}>
                                            <Typography variant="subtitle2" component="p">
                                                {t('summary.modalities')}&nbsp;
                                                <Typography variant="body1" component="span">
                                                    {userData?.modality}
                                                </Typography>
                                            </Typography>
                                            <Typography variant="subtitle2" component="p">
                                                {t('summary.careers')}&nbsp;
                                                <Typography variant="body1" component="span">
                                                    {userData?.career}
                                                </Typography>
                                            </Typography>
                                            {
                                                (userData.requiresUniversity && userData.university) ?
                                                    <>
                                                        <hr />
                                                        <Typography variant="subtitle2" component="p">
                                                            {t('summary.university-data')}&nbsp;
                                                            <Typography variant="body1" component="span">
                                                                {userData.university.notFound ? t('summary.university-not-found') : userData.university?.name}
                                                                {
                                                                    !userData.university.notFound ?
                                                                        <>
                                                                            <Typography variant="subtitle2" component="p">
                                                                                {t('summary.university-first-term')}&nbsp;
                                                                                <Typography variant="body1" component="span">
                                                                                    {userData.university.firstPeriodYear}-{userData.university.firstPeriodCycle}
                                                                                </Typography>
                                                                            </Typography>
                                                                            <Typography variant="subtitle2" component="p">
                                                                                {t('summary.university-last-term')}&nbsp;
                                                                                <Typography variant="body1" component="span">
                                                                                    {userData.university.lastPeriodYear}-{userData.university.lastPeriodCycle}
                                                                                </Typography>
                                                                            </Typography>
                                                                        </> : <></>
                                                                }
                                                            </Typography>
                                                        </Typography>
                                                    </> : <></>
                                            }

                                        </div>
                                    </ListItem>
                                </List>
                            </Grid>

                            <Grid item xs={12} >
                                <ULButton onClick={confirmProvidedInformation} working={working}>
                                    {canEdit ? t('buttons.confirm-data') : t('buttons.continue')}
                                </ULButton>
                            </Grid>
                        </Grid>
                    </>
            }
        </>
    )
}

export default ApplicationSummary;