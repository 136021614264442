import { useState } from "react";
import { createContext } from "react";
import { getApplicationDataLS, setApplicationLS, setCallLS, setOMRCodeLS, setVerificationCodeValidatedLS } from "../utils/CommonFunctions";
import { useEffect } from "react";

const ApplicationContext = createContext();
export default ApplicationContext;

export const ApplicationContextProvider = ({ children }) =>
{
    const [applicationID, setApplicationID] = useState(getApplicationDataLS()._applicationID);
    const [callID, setCallID] = useState(getApplicationDataLS()._callID);
    const [verificationCodeValidated, setVerificationCodeValidated] = useState(getApplicationDataLS()._verificationCodeValidated);
    const [OMR, setOMRCode] = useState(getApplicationDataLS()._omr);
    const [correcting, setCorrecting] = useState(false);

    useEffect(() =>
    {
        setApplicationLS(parseInt(applicationID));
    }, [applicationID])

    useEffect(() =>
    {
        setCallLS(parseInt(callID));
    }, [callID])

    useEffect(() =>
    {
        setVerificationCodeValidatedLS(verificationCodeValidated);
    }, [verificationCodeValidated])

    useEffect(() =>
    {
        setOMRCodeLS(OMR);
    }, [OMR]);

    const resetApplicationContext = () =>
    {
        setApplicationID(0);
        setCallID(0);
        setVerificationCodeValidated(false);
        setOMRCode('');
        setCorrecting(false);
    }

    return (
        <ApplicationContext.Provider value={{
            applicationID: applicationID,
            callID: callID,
            verificationCodeValidated: verificationCodeValidated,
            OMR: OMR,
            correcting: correcting,
            setApplicationID: setApplicationID,
            setCallID: setCallID,
            setVerificationCodeValidated: setVerificationCodeValidated,
            setOMRCode: setOMRCode,
            setCorrecting: setCorrecting,
            resetApplicationContext: resetApplicationContext,
        }}>
            {children}
        </ApplicationContext.Provider>

    )
}