import { createContext } from "react";
import { getCareerIDLS, getDefaultUniversityData, getModalityIDLS, getRequiresUniversityLS, getUniversityLS, setCareerIDLS, setModalityIDLS, setUniversityLS } from "../utils/CommonFunctions";
import { useState } from "react";
import { useEffect } from "react";

const ModalityAndCareerContext = createContext();
export default ModalityAndCareerContext;

export const ModalityAndCareerContextProvider = ({ children }) =>
{
    const [universityData, setUniversityData] = useState(getUniversityLS());
    const [modalityID, setModalityID] = useState(getModalityIDLS());
    const [careerID, setCareerID] = useState(getCareerIDLS());
    const [requiresUniversity, setRequiresUniversity] = useState(getRequiresUniversityLS());

    useEffect(() =>
    {
        setModalityIDLS(modalityID);
    }, [modalityID])

    useEffect(() =>
    {
        setCareerIDLS(careerID);
    }, [careerID])

    useEffect(() =>
    {
        setUniversityLS(universityData);
    }, [universityData]);

    const resetModalityAndCareerContext = () => {
        setUniversityData(getDefaultUniversityData());
        setModalityID(0);
        setCareerID(0);
    }

    return (
        <ModalityAndCareerContext.Provider value={{
            universityData: universityData,
            setUniversityData: setUniversityData,
            modalityID: modalityID,
            setModalityID: setModalityID,
            careerID: careerID,
            setCareerID: setCareerID,
            requiresUniversity: requiresUniversity,
            setRequiresUniversity: setRequiresUniversity,
            resetModalityAndCareerContext: resetModalityAndCareerContext,
        }}>
            {children}
        </ModalityAndCareerContext.Provider>
    )
}