import i18n from 'i18next';

import { initReactI18next } from 'react-i18next';
// import LanguageDetector from "i18next-browser-languagedetector";

import es from './translations/es.json';


const resources = {
  es: { translation: es }
};

i18n
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: "es",
    resources,
    interpolation: {
      escapeValue: false // react already safe from xss
    }
  });

export default i18n;
