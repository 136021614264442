import { Alert, Grid, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import * as ROUTES from "../../routes/routes";
import TextField from '@mui/material/TextField';
import { useTranslation } from "react-i18next";
import { useState, useEffect, useContext } from "react";
import * as APIServices from "../../services/rest/APIServices";
import ApplicationContext from "../../context/ApplicationContext";
import ULButton from "../../components/ULButton";
import { SCROLLABLE_AREA_NAME, stepRoute } from "../../utils/CommonFunctions";

const WAIT_TIME = 60;

const StudentCodeValidation = (props) =>
{
    const { applicationID, setVerificationCodeValidated, setOMRCode } = useContext(ApplicationContext);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const location = useLocation();
    const instructions = location?.state?.instructions;
    const cellphone = location.state?.cellphone;
    const from = location.state?.from;

    if (!cellphone && !from) navigate(ROUTES.STUDENT_PERSONAL_DATA);
    const email = location.state?.email;
    if (!email && !from) navigate(ROUTES.STUDENT_PERSONAL_DATA);
    const step = location.state?.step;
    const fromApplicationId = location.state?.fromApplicationId;
    const requestTypeId = location.state?.requestTypeId;
    const [code, setCode] = useState('');
    const [showCode, setShowCode] = useState(location.state?.showCode ?? false);
    const [codePreview, setCodePreview] = useState(location.state?.codePreview ?? '');
    const [errMessages, setErrMessages] = useState([]);

    const [workingRS, setWorkingRS] = useState(false);
    const [workingSV, setWorkingSV] = useState(false);

    const [waitTime, setWaitTime] = useState(WAIT_TIME);
    const [canGetCode, setCanGetCode] = useState(false);

    const [prevFrom, setPrevFrom] = useState(from);

    useEffect(() =>
    {
        if (from !== prevFrom)
        {
            if (from === "continue") requestCode();
            setPrevFrom(from);
        }
        // eslint-disable-next-line
    }, [from, prevFrom])

    useEffect(() =>
    {
        let interval;

        if (!canGetCode && waitTime > 0)
        {
            interval = setInterval(() =>
            {
                setWaitTime((prevTime) => prevTime - 1);
            }, 1000);
        }
        else
        {
            setCanGetCode(true);
        }

        return () => clearInterval(interval);
    }, [canGetCode, waitTime]);

    const requestCode = (e) =>
    {
        setWorkingRS(true);
        setErrMessages([]);
        APIServices.requestVerificationCode({
            applicationId: !from ? applicationID : fromApplicationId,
            requestTypeId: requestTypeId,
        })
            .then((response) =>
            {
                // El código ha sido solicitado y se está a la espera
                const showCode = response.data?.data?.showCode ?? false;
                const code = response.data?.data?.code ?? '';
                setShowCode(showCode);
                setCodePreview(code);
            })
            .catch((error) =>
            {
                setErrMessages(error.response ? error.response.data?.messages ?? [{ code: "", message: t('errors.unknown') }] : [{ code: "", message: t('errors.network') }])
                const scrollableArea = document.getElementById(SCROLLABLE_AREA_NAME);
                scrollableArea.scrollTo({ top: 0, behavior: 'smooth' });
                window.scrollTo({ top: 0, behavior: 'smooth' });
            })
            .finally(() =>
            {
                setWorkingRS(false);
            })
    }

    const validateCode = (e, code) =>
    {
        setWorkingSV(true);
        setErrMessages([]);
        APIServices.validateVerificationCode({
            applicationId: !from ? applicationID : fromApplicationId,
            email: email,
            code: code,
        })
            .then((response) =>
            {
                const data = response.data.data;
                setVerificationCodeValidated(data.valid);
                setOMRCode(data.omr);
                if (from === "continue" && step > -1)
                {
                    navigate(stepRoute(step));
                }
                else
                {
                    navigate(ROUTES.EDUCATION_FIFTH_SCHOOL_TYPE);
                }
            })
            .catch((error) =>
            {
                setErrMessages(error.response ? error.response.data?.messages ?? [{ code: "", message: t('errors.unknown') }] : [{ code: "", message: t('errors.network') }])
                const scrollableArea = document.getElementById(SCROLLABLE_AREA_NAME);
                scrollableArea.scrollTo({ top: 0, behavior: 'smooth' });
                window.scrollTo({ top: 0, behavior: 'smooth' });
            })
            .finally(() =>
            {
                setWorkingSV(false);
            })
    }

    const resendCode = (e) =>
    {
        if (canGetCode)
        {
            requestCode(e);
            setWaitTime(WAIT_TIME);
            setCanGetCode(false);
        }
        // navigate(ROUTES.EDUCATION_FIFTH_SCHOOL_TYPE)
    }

    const continueOperation = (e) =>
    {
        if (code.length > 0)
        {
            validateCode(e, code);
        }
    }

    useEffect(() =>
    {
        props.reRenderLayout();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Grid container direction="column" spacing={2}>
            <Grid item xs={12}>
                {
                    errMessages?.length ? <Alert role="alert" severity="error">
                        {
                            errMessages.map((err, index) => (
                                <Typography key={index}> {err.code?.length ? `[${err.code}]` : ""} {err.message}</Typography>
                            ))
                        }
                    </Alert> : <></>
                }
            </Grid>

            <Grid item xs={12}>
                <Typography variant="h5" component="h4" style={{ textDecoration: 'underline', fontWeight: '500' }}>
                    {instructions + t('code-verification.verification-confirm.title-2')}
                </Typography>
            </Grid>
            {
                !canGetCode ?
                    <>
                        <Grid item xs={12}>
                            <Typography variant="p" component="p">
                                {t('code-verification.verification-confirm.remaining-time-can-get-new-code', { time: waitTime })}
                            </Typography>
                        </Grid>
                    </> : <></>
            }

            <Grid item xs={12}>
                <TextField value={code} onChange={(e) => setCode(e.target.value)} inputProps={{ maxLength: 5 }} fullWidth label={t('code-verification.verification-send.label-verification-code')} />
            </Grid>

            {
                showCode ?
                    <>
                        <Grid item xs={12}>
                            <Alert severity="info"><Typography variant="h4" element="h4">{t('code-verification.verification-confirm.code-preview', { code: codePreview })}</Typography></Alert>
                        </Grid>
                    </> : <></>
            }

            <Grid item xs={12}>
                <ULButton disabled={code.length !== 5} working={workingSV} onClick={continueOperation} > {t('code-verification.verification-send.label-verify')}</ULButton>
            </Grid>

            <Grid item xs={12}>
                <ULButton variant="outlined" disabled={!canGetCode} working={workingRS} onClick={resendCode}>{t('code-verification.verification-send.label-resend-verification-code')}</ULButton>
            </Grid>

            <Grid item xs={12}>
                <ULButton variant="outlined" color="secondary" onClick={() => navigate(from === "continue" ? ROUTES.APPLICATION_CONTINUE : ROUTES.STUDENT_CHOOSE_VERIFICATION_TYPE)} goBack={true}>{t('buttons.back')}</ULButton>
            </Grid>
        </Grid>
    )
}

export default StudentCodeValidation;