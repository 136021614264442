import React from 'react'
import { Grid } from "@mui/material";
import ULimaLight from "../../assets/icons/Logo_Ulima.svg"
import "./TopBar.css";
import { useTranslation } from 'react-i18next';

const TopBar = (props) =>
{
  const { t } = useTranslation();

  return (
    <Grid container className="layoutContainer">
      <Grid item container xs={12} md={12} alignItems="center" justifyContent="space-around" paddingLeft="0vh" paddingBottom="0vh">
        <img
          src={ULimaLight}
          alt={t('top-bar.ulima-logo-alt')}
          className="layoutImage"
        />
      </Grid>
    </Grid>
  );
}

export default TopBar;
