export const MENU = "/";
export const LOGIN = "/login";
export const ACTIVITIES = "/activities";
export const APPLICATION_NEW_APPLICANT = "/applicants/new";
export const STUDENT_IDENTIFICATION = "/student-info/identification";
export const STUDENT_PERSONAL_DATA = "/student-info/personal-data";
export const STUDENT_CHOOSE_VERIFICATION_TYPE = "/student-info/choose-verification-type";
export const STUDENT_CODE_VALIDATION = "/student-info/code-validation";
export const EDUCATION_FIFTH_SCHOOL_TYPE = "/education/fifth-school-type";
export const EDUCATION_FIFTH_LOCAL_SCHOOL = "/education/fifth-local-school";
export const EDUCATION_FOURTH_SCHOOL_TYPE = "/education/fourth-school-type";
export const EDUCATION_FOURTH_LOCAL_SCHOOL = "/education/fourth-local-school";
export const EDUCATION_THIRD_SCHOOL_TYPE = "/education/third-school-type";
export const EDUCATION_THIRD_LOCAL_SCHOOL = "/education/third-local-school";
export const EDUCATION_FIFTH_SCHOOL_NOT_FOUND = "/education/fifth-school-not-found";
export const EDUCATION_FOURTH_SCHOOL_NOT_FOUND = "/education/fourth-school-not-found";
export const EDUCATION_THIRD_SCHOOL_NOT_FOUND = "/education/third-school-not-found";

export const MODALITY_AND_CARREERS_UNIVERSITY = "/modalities-and-careers/university-local";
export const MODALITY_AND_CARREERS_UNIVERSITY_FOREIGN = "/modalities-and-careers/university-foreign";
export const MODALITY_AND_CARREERS_UNIVERSITY_TYPE = "/modalities-and-careers/university-type";
export const MODALITY_AND_CARREERS_UNIVERSITY_NOT_FOUND = "/modalities-and-careers/university-not-found";

export const PAYMENTS = "/payment";
export const SUCCESSFUL_PAYMENT = "/payment/success";
export const CASH_PAYMENT = "/cash-payment";
export const MODALITY_AND_CARREERS_CHOOSE_MODALITY = "/modalities-and-careers/modalities";
export const MODALITY_AND_CARREERS_CHOOSE_CAREER = "/modalities-and-careers/careers";
export const APPLICATION_SUMMARY = "/application-summary";

export const APPLICATION_CONTINUE = "/application/continue";
export const APPLICATION_RECOVER_OMR = "/application/recover-omr";
export const APPLICATION_SUSPEND = "/application/suspend";
export const VERIFICATION_CODE_SENDER = "/application/verification-code/sender";
export const VERIFICATION_CODE_VIEW = "/application/verification-code/view";
export const DOCUMENTS_REQUESTED_DOCUMENTS = "/documents/requested";
export const DOCUMENTS_CARNE_PHOTO = "/documents/photo";
export const DOCUMENTS_CARNE_PHOTO_VALIDATION_RESULTS = "/documents/photo/validation/:verificationId";
export const DOCUMENTS_CARNE_PHOTO_VALIDATION_RESULTS_PART = "/documents/photo/validation";

export const DOCUMENT_VERIFICATION = "/documents/verification";
export const DOCUMENT_VERIFICATION_STUDENT_PERSONAL_DATA = "/documents/verification/info";
export const APPLICANTS_CARD = "/applicants-card";

export const DISCLAIMERS_BASE = "/disclaimers";
export const DISCLAIMERS = `${DISCLAIMERS_BASE}/:uuid`;