import { useState } from "react";
import { createContext } from "react";

const DocumentsContext = createContext();
export default DocumentsContext;

export const DocumentsContextProvider = ({ children }) =>
{
    const [documentsTermsAndConditions, setDocumentsTermsAndConditions] = useState(false)

    const resetDocumentsContext = () => {
        setDocumentsTermsAndConditions(false);
    }

    return (
        <DocumentsContext.Provider value={{
            documentsTermsAndConditions: documentsTermsAndConditions,
            setDocumentsTermsAndConditions: setDocumentsTermsAndConditions,
            resetDocumentsContext: resetDocumentsContext,
        }}>
            {children}
        </DocumentsContext.Provider>

    )
}