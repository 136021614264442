export const handlePreventTypingChars = (e) =>
{
    const key = e.key;
    if (!(key === 'Backspace' || key === 'ArrowLeft' || key === 'ArrowRight' || key === 'Tab'))
    {
        if (!/[0-9\b]/.test(key))
        {
            e.preventDefault();
        }
    }
}

export const handlePreventInvalidPhoneNumbers = (e) =>
{
    const key = e.key;
    const str = e.target.value;
    const repeatedPlus = str.split('').filter(c => c === '+').length;
    if (key === '+' && (repeatedPlus > 0 || e.target.selectionStart > 0))
        e.preventDefault();
    if (!(key === 'Backspace' || key === 'ArrowLeft' || key === 'ArrowRight' || key === 'Tab'))
    {
        if (!/^[+\d\s]+$/.test(key))
            e.preventDefault();
    }
}


export const isValidEmail = (email) =>
{
    var regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return regex.test(email);
}

export const MIN_DATE = 1900;