export const API_GET_APPLICATIONS_LIST = '/api/v1/call/list';
export const API_GET_DOCUMENT_TYPE_LIST = '/api/v1/document-type/list';
export const API_FETCH_APPLICANT_DOCUMENT = '/api/v1/application/fetch-applicant-document';
export const API_SAVE_APPLICANT_DOCUMENT = '/api/v1/application/save-applicant-document';
export const API_FETCH_APPLICANT_INFO = '/api/v1/application/fetch-applicant-info';
export const API_SAVE_APPLICANT_INFO = '/api/v1/application/save-applicant-info';
export const API_REQUEST_VERIFICATION_CODE = '/api/v1/request-code/generate';
export const API_VALIDATE_VERIFICATION_CODE = '/api/v1/request-code/validate';
export const API_GET_REQUEST_CODE_TYPE_LIST = '/api/v1/request-code/list-types';
export const API_GET_SCHOOL_TYPE_LIST = '/api/v1/school/list-types';
export const API_SCHOOL_AUTOCOMPLETE_LIST = '/api/v1/school/autocomplete';

export const API_GET_UNIVERSITY_TYPE_LIST = '/api/v1/university/list-types';
export const API_UNIVERSITY_AUTOCOMPLETE_LIST = '/api/v1/university/autocomplete';
export const API_UNIVERSITY_INTERNATIONAL_AUTOCOMPLETE_LIST = '/api/v1/university/autocomplete';

export const API_GEOLOCATION_AUTOCOMPLETE_LIST = '/api/v1/geolocation/autocomplete';
export const API_GEOLOCATION_INTERNATIONAL_AUTOCOMPLETE_LIST = '/api/v1/geolocation/autocomplete-country';


export const API_SAVE_EDUCATION_INFO_FIFTH_GRADE = '/api/v1/application/fifth-grade/save-education-info';
export const API_SAVE_EDUCATION_INFO_FOURTH_GRADE = '/api/v1/application/fourth-grade/save-education-info';
export const API_SAVE_EDUCATION_INFO_THIRD_GRADE = '/api/v1/application/third-grade/save-education-info';

export const API_FETCH_EDUCATION_INFO_FIFTH_GRADE = '/api/v1/application/fifth-grade/fetch-education-info';
export const API_FETCH_EDUCATION_INFO_FOURTH_GRADE = '/api/v1/application/fourth-grade/fetch-education-info';
export const API_FETCH_EDUCATION_INFO_THIRD_GRADE = '/api/v1/application/third-grade/fetch-education-info';

export const API_GET_MODALITY_LIST = '/api/v1/modality/list';
export const API_GET_CAREER_LIST = '/api/v1/career/list';
export const API_FETCH_MODALITY_AND_CAREER_INFO = '/api/v1/application/fetch-modality-career-info';
export const API_SAVE_MODALITY_AND_CAREER_INFO = '/api/v1/application/save-modality-career-info';

export const API_UPLOAD_MODALITY_AND_CAREER_UNIVERSITY_FILE_NF = '/api/v1/application/upload-university-document';
export const API_DELETE_MODALITY_AND_CAREER_UNIVERSITY_FILE_NF = '/api/v1/application/delete-university-document';
export const API_DOWNLOAD_MODALITY_AND_CAREER_UNIVERSITY_FILE_NF = '/api/v1/application/download-university-document';
export const API_LIST_MODALITY_AND_CAREER_UNIVERSITY_FILES_NF = '/api/v1/application/list-university-documents';

export const API_FETCH_APPLICATION_SUMMARY_INFO = '/api/v1/application/summary';

export const API_UPLOAD_FILE_NF = '/api/v1/application/upload-school-document';
export const API_DELETE_FILE_NF = '/api/v1/application/delete-school-document';
export const API_DOWNLOAD_FILE_NF = '/api/v1/application/download-school-document';
export const API_LIST_FILES_NF = '/api/v1/application/list-school-documents';

export const API_UPLOAD_FILE_DC = '/api/v1/application/upload-requirement';
export const API_DELETE_FILE_DC = '/api/v1/application/delete-requirement';
export const API_DOWNLOAD_FILE_DC = '/api/v1/application/download-requirement';
export const API_LIST_FILES_DC = '/api/v1/application/fetch-requirements';

export const API_UPDATE_STEP = '/api/v1/application/step';

export const API_SAVE_TERMS_AGREEMENT = '/api/v1/application/save-requirements';


export const API_UPDATE_REQUIREMENT_DC = '/api/v1/application/update-validated-requirement';
export const API_CONFIRM_PROVIDED_INFORMATION = '/api/v1/application/confirm';

export const API_CONFIRM_APPLICANT_DATA = '/api/v1/application/confirm-applicant-data';

export const API_APPLICATION_CONTINUE = '/api/v1/application/continue';

export const API_DOWNLOAD_TEMPLATE_DC = '/api/v1/application/download-template';

export const API_DOWNLOAD_DISCLAIMER = '/api/v1/disclaimer/download';

export const API_RECOVER_OMR = '/api/v1/application/recover-omr';

export const API_LIST_FILES_VERIFICATION_NF = '/api/v1/application/list-verification-school-documents';
export const API_LIST_FILES_VERIFICATION_DC = '/api/v1/application/fetch-verification-documents';
export const API_LIST_MODALITY_AND_CAREER_UNIVERSITY_FILES_VERIFICATION_NF = '/api/v1/application/list-verification-university-documents';
export const API_LIST_FILES_VERIFICATION_PAYMENT = '/api/v1/admin/document/fetch';
export const API_VERIFICATION_FINISH_CORRECTION = '/api/v1/application/finish-corrections';
export const API_UPDATE_APPLICANT_INFO = '/api/v1/application/update-applicant-info';


export const API_DUMMY_PAYMENT = '/api/v1/temp/application-paid';
export const API_FETCH_APPLICANT_INFO_REVIEW = '/api/v1/application/fetch-applicant-info-review';

export const API_LIST_DISCLAIMERS = '/api/v1/disclaimer/list';
export const API_CARD_EXPORT = 'api/v1/application/download-card';
export const API_CARD_FETCH= 'api/v1/application/card-summary';
export const API_CARD_PHOTO_GET= 'api/v1/admin/image/get';

export const API_FETCH_PAYMENT_INFO = '/api/v1/application/fetch-payment-info';

export const API_GET_HEADER_DATA = '/api/v1/application/header-data';