import { Alert, AlertTitle, Grid, Typography } from "@mui/material";
import { useState, useEffect, Fragment } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import * as ROUTES from "../../routes/routes";
import { useTranslation } from "react-i18next";
import axios from "axios";
import ULButton from "../../components/ULButton";
import { SCROLLABLE_AREA_NAME } from "../../utils/CommonFunctions";
import Loading from "../../components/Loading";

const VERIFICATOR_USERNAME = process.env.REACT_APP_VERIFICATOR_USERNAME;
const VERIFICATOR_ENDPOINT = process.env.REACT_APP_VERIFICATOR_ENDPOINT;
const VERIFICATOR_ACCESS_KEY = process.env.REACT_APP_VERIFICATOR_ACCESS_KEY;
const VERIFICATOR_SECRET_KEY = process.env.REACT_APP_VERIFICATOR_SECRET_KEY;
const VERIFICATOR_AUTH_API = process.env.REACT_APP_VERIFICATOR_AUTH_API;
const VERIFICATOR_SUMMARY_API = process.env.REACT_APP_VERIFICATOR_SUMMARY_API;
const VERIFICATOR_IMAGE_GET_API = process.env.REACT_APP_VERIFICATOR_IMAGE_GET_API;

const ValidationResults = (props) =>
{

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { verificationId } = useParams();
    const [searchParams] = useSearchParams();
    const requirementId = parseInt(searchParams.get('requirementId') ?? '0');
    const from = searchParams.get('from') ?? 'requirements';
    const [data, setData] = useState(null);
    const [photoPrev, setPhotoPrev] = useState("");
    const [errMessages, setErrMessages] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const handleContinue = () =>
    {
        if (from === 'requirements')
            navigate(ROUTES.DOCUMENTS_REQUESTED_DOCUMENTS)
        if (from === 'verifications')
            navigate(ROUTES.DOCUMENT_VERIFICATION)
    }


    const getResult = (verificationId) =>
    {
        setIsLoading(true);
        axios.post(`${VERIFICATOR_ENDPOINT}${VERIFICATOR_AUTH_API}`, {
            accessKey: VERIFICATOR_ACCESS_KEY,
            secretKey: VERIFICATOR_SECRET_KEY,
        })
            .then((response) =>
            {
                const access_token = response.data.data.token;
                axios.get(`${VERIFICATOR_ENDPOINT}${VERIFICATOR_SUMMARY_API}?verificationId=${verificationId}&username=${VERIFICATOR_USERNAME}`, {
                    headers: {
                        Authorization: 'Bearer ' + access_token,
                        'Content-Type': 'application/json',
                        accept: 'application/json',
                    },
                })
                    .then((response) =>
                    {
                        let data = response.data.data;
                        const compareByOrderId = (a, b) =>
                        {
                            return a.order - b.order;
                        }

                        // Ordenar el arreglo de objetos utilizando la función de comparación
                        data.parameters.sort(compareByOrderId);
                        setData(data);
                        axios.get(`${VERIFICATOR_ENDPOINT}${VERIFICATOR_IMAGE_GET_API}?uuid=${data.uuid}`, {
                            responseType: 'arraybuffer',
                            headers: {
                                Authorization: 'Bearer ' + access_token,
                                'Content-Type': 'application/json',
                                accept: 'application/json',
                            },
                        })
                            .then((response) =>
                            {
                                const imageContentType = 'image/jpeg';
                                const imageBinary = new Blob([response.data], { type: imageContentType });
                                const imageUrl = URL.createObjectURL(imageBinary);
                                setData(data);
                                setPhotoPrev(imageUrl)
                            })
                            .catch(err => console.error(err));
                    })
                    .catch((err) => { console.log(err); })
            })
            .catch((err) => { console.log(err); })
            .finally(() =>
            {
                setIsLoading(false);
            });

    }

    useEffect(() =>
    {
        if (verificationId && requirementId)
        {
            getResult(verificationId);
        }
        else
        {
            setErrMessages([{ code: "", message: t('validation-results.invalid-invocation-error') }]);
            const scrollableArea = document.getElementById(SCROLLABLE_AREA_NAME);
            scrollableArea.scrollTo({ top: 0, behavior: 'smooth' });
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {
                isLoading ?
                    <>
                        <Loading />
                    </> :
                    <>
                        <Grid container direction="column" spacing={2}>
                            {
                                errMessages?.length ?
                                    <Grid item xs={12}>
                                        <Alert role="alert" severity="error">
                                            {
                                                errMessages.map((err, index) => (
                                                    <Typography key={index}> {err.code?.length ? `[${err.code}]` : ""} {err.message}</Typography>
                                                ))
                                            }
                                        </Alert>
                                    </Grid>
                                    : <></>
                            }
                            <Grid item xs={12}>
                                <Typography variant="h5" component="h4" style={{ textDecoration: 'underline', fontWeight: '500' }}>
                                    {t('validation-results.title')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                {
                                    photoPrev.length ?
                                        <>
                                            <img alt={t('validation-results.uploaded-picture')} src={photoPrev} style={{ maxHeight: '12rem', display: 'block', margin: '0 auto', borderRadius: '1rem', border: 'solid 1px #FF5117' }} />
                                        </> : <></>
                                }
                            </Grid>
                            <Grid item container xs={12}>
                                {
                                    data ?
                                        <>
                                            {
                                                data.valid ?
                                                    <>
                                                        <Alert severity="success" style={{ borderRadius: '1rem', width: '100%' }}>{t('validation-results.valid-msg')}</Alert>
                                                    </> : <>
                                                        <Alert severity="error" style={{ borderRadius: '1rem', width: '100%' }}>
                                                            <AlertTitle>{t('validation-results.invalid-title')}</AlertTitle>
                                                            {t('validation-results.invalid-msg')}
                                                        </Alert>
                                                    </>
                                            }
                                        </> : <></>
                                }
                            </Grid>
                            {
                                data?.parameters?.map((parameter, index) =>
                                (
                                    <Fragment key={index}>
                                        {
                                            !parameter.valid ?
                                                <Grid item xs={12}>
                                                    <Alert icon={<></>} severity="warning" style={{ borderRadius: '10rem', padding: '.25rem .5rem' }}>{parameter.errorMessage}</Alert>
                                                </Grid> : <></>
                                        }
                                    </Fragment>
                                ))
                            }
                            {
                                data?.biometrics?.map((parameter, index) =>
                                (
                                    <Fragment key={index}>
                                        {
                                            !parameter.valid ?
                                                <Grid item xs={12}>
                                                    <Alert icon={<></>} severity="warning" style={{ borderRadius: '10rem', padding: '.25rem .5rem' }}>{parameter.errorMessage}</Alert>
                                                </Grid> : <></>
                                        }
                                    </Fragment>
                                ))
                            }
                            {
                                data?.valid ?
                                    <>
                                        <Grid item xs={12} >
                                            <ULButton onClick={handleContinue}>
                                                {t('buttons.continue')}
                                            </ULButton>
                                        </Grid>
                                    </> : <>
                                        <Grid item xs={12}>
                                            <ULButton onClick={() => { navigate(`${ROUTES.DOCUMENTS_CARNE_PHOTO}?requirementId=${requirementId}&from=${from}`) }}>
                                                {t('buttons.re-upload-photo')}
                                            </ULButton>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <ULButton variant="outlined">
                                                {t('validation-results.get-help-btn')}
                                            </ULButton>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <ULButton
                                                variant="outlined" color="secondary"
                                                onClick={() => { navigate(ROUTES.APPLICATION_SUSPEND, { state: { step: 3 } }) }}
                                            >
                                                {t('buttons.continue-later')}
                                            </ULButton>
                                        </Grid>
                                    </>
                            }
                        </Grid>
                    </>
            }
        </>
    )
}

export default ValidationResults;