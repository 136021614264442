
import { Accordion, AccordionDetails, AccordionSummary, Alert, Card, CardContent, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import * as ROUTES from "../../routes/routes";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { useTranslation } from 'react-i18next';
import { useState } from "react";
import TextField from '@mui/material/TextField';
import * as APIServices from "../../services/rest/APIServices";
import ULButton from "../../components/ULButton";
import Loading from "../../components/Loading";
import { SCROLLABLE_AREA_NAME } from "../../utils/CommonFunctions";
import { useEffect } from "react";
import { handlePreventInvalidPhoneNumbers, handlePreventTypingChars, isValidEmail } from "../../utils/Validations";
import { Check, CopyAllOutlined, ExpandMoreOutlined } from "@mui/icons-material";
import CopyToClipboard from "react-copy-to-clipboard";

const ApplicationRecoverOMR = (props) =>
{
    const navigate = useNavigate();
    const { t } = useTranslation();
    const location = useLocation();
    const [documentType, setDocumentType] = useState(location.state?.documentType ?? 0);
    const [documentNumber, setDocumentNumber] = useState(location.state?.documentNumber ?? '');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [requestCodeType, setRequestCodeType] = useState(0); /**/
    const [callId, setCallId] = useState(0);

    const [documentTypeList, setDocumentTypeList] = useState([]);
    const [requestCodeTypeList, setRequestCodeTypeList] = useState([]);
    const [callsList, setCallsList] = useState([]);
    const [errMessages, setErrMessages] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [working, setWorking] = useState(false);
    const [omr, setOmr] = useState('');
    const [copied, setCopied] = useState(false);

    const [invalidEmailFeedback, setInvalidEmailFeedback] = useState(false);

    const requestOMR = () =>
    {
        setWorking(true);
        setErrMessages([]);
        APIServices.recoverOMR({
            callId: callId,
            documentTypeId: documentType,
            documentNumber: documentNumber,
            requestTypeId: requestCodeType,
            email: email,
            cellPhone: phone,
        })
            .then((response) =>
            {
                const data = response.data?.data;
                const omr = data.omr;
                setOmr(omr ?? '');
            })
            .catch((error) =>
            {
                console.log(error);
                setErrMessages(error.response ? error.response.data?.messages ?? [{ code: "", message: t('errors.unknown') }] : [{ code: "", message: t('errors.network') }])
                const scrollableArea = document.getElementById(SCROLLABLE_AREA_NAME) ?? window;
                scrollableArea.scrollTo({ top: 0, behavior: 'smooth' });
                window.scrollTo({ top: 0, behavior: 'smooth' });
            })
            .finally(() =>
            {
                setWorking(false);
            })
    }

    const handleContinue = () =>
    {
        const selectedCodeType = requestCodeTypeList.find(dt => dt.requestTypeId === requestCodeType)?.type ?? 'UNSELECTED';

        if (selectedCodeType === "EMAIL" && !isValidEmail(email))
        {
            setInvalidEmailFeedback(true);
            return;
        }
        requestOMR();
    }

    const handleChangeDocumentType = (e) =>
    {
        setDocumentType(parseInt(e.target.value));
    }

    const handleChangePhone = (e) =>
    {
        setPhone(e.target.value)
    }

    const handleChangeEmail = (e) =>
    {
        invalidEmailFeedback && setInvalidEmailFeedback(false);
        setEmail(e.target.value.toLowerCase());
    }

    useEffect(() =>
    {
        const callServices = async () =>
        {
            setIsLoading(true);
            await APIServices.getRequestCodeTypeList().then((response) =>
            {
                setRequestCodeTypeList(response.data.data?.list ?? []);
            })
                .catch((error) =>
                {
                    setErrMessages(error.response ? (error.response.data?.messages ?? [{ code: "", message: t('errors.unknown') }]) : [{ code: "", message: t('errors.network') }]);
                    const scrollableArea = document.getElementById(SCROLLABLE_AREA_NAME);
                    scrollableArea.scrollTo({ top: 0, behavior: 'smooth' });
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                })
                .finally(() =>
                {
                    setIsLoading(false);
                })
        }
        callServices();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() =>
    {
        const callServices = async () =>
        {
            setIsLoading(true);
            await APIServices.getApplicationsList()
                .then((response) =>
                {
                    let callsList = response.data.data?.list ?? [];
                    callsList.sort((a, b) => a.callId - b.callId)
                    setCallsList(callsList);
                })
                .catch((error) =>
                {
                    setErrMessages(error.response ? (error.response.data?.messages ?? [{ code: "", message: t('errors.unknown') }]) : [{ code: "", message: t('errors.network') }]);
                    const scrollableArea = document.getElementById(SCROLLABLE_AREA_NAME);
                    scrollableArea.scrollTo({ top: 0, behavior: 'smooth' });
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                })
                .finally(() =>
                {
                    setIsLoading(false);
                })
        }
        callServices();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() =>
    {
        const callServices = async () =>
        {
            setIsLoading(true);
            await APIServices.getDocumentTypeList().then((response) =>
            {
                setDocumentTypeList(response.data.data?.list ?? []);
            })
                .catch((error) =>
                {
                    setErrMessages(error.response ? (error.response.data?.messages ?? [{ code: "", message: t('errors.unknown') }]) : [{ code: "", message: t('errors.network') }]);
                    const scrollableArea = document.getElementById(SCROLLABLE_AREA_NAME);
                    scrollableArea.scrollTo({ top: 0, behavior: 'smooth' });
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                })
                .finally(() =>
                {
                    setIsLoading(false);
                })
        }
        callServices();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isDNISelected = documentTypeList.find(dt => dt.documentTypeId === documentType)?.isMain ?? false;
    const selectedCodeType = requestCodeTypeList.find(dt => dt.requestTypeId === requestCodeType)?.type ?? 'UNSELECTED';

    return (
        <>
            {
                isLoading ?
                    <>
                        <Loading />
                    </> :
                    <>
                        <Grid container direction="column" spacing={2}>
                            {
                                errMessages?.length ?
                                    <Grid item xs={12}>
                                        <Alert role="alert" severity="error">
                                            {
                                                errMessages.map((err, index) => (
                                                    <Typography key={index}> {err.code?.length ? `[${err.code}]` : ""} {err.message}</Typography>
                                                ))
                                            }
                                        </Alert>
                                    </Grid>
                                    : <></>
                            }
                            {
                                omr.length ?
                                    <>
                                        <Grid item xs={12}>
                                            <Typography variant="h5" component="h4">{t('application-recover-omr.omr-title')}</Typography>
                                            <Card variant="outlined" sx={{ minWidth: 275, my: 2, borderRadius: '1rem' }}>
                                                <CardContent>
                                                    <Typography align="center" variant="h0" component="h1">{omr}</Typography>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CopyToClipboard text={omr}
                                                onCopy={() =>
                                                {
                                                    setCopied(true);
                                                    setTimeout(() => {
                                                        setCopied(false);
                                                    }, 5000);
                                                }}>
                                                <ULButton startIcon={<CopyAllOutlined />}>{t('common.copy')}</ULButton>
                                            </CopyToClipboard>
                                        </Grid>
                                        {
                                            copied ?
                                                <>
                                                    <Grid item xs={12}>
                                                        <div className="disclaimer" align='center'> {t('application-recover-omr.omr-copied', { omr: omr })} </div>
                                                    </Grid>
                                                </> : <></>
                                        }
                                        <Grid item xs={12}>
                                            <Typography align="center" variant="p" component="small">{t('application-recover-omr.email-sent-notice')}</Typography>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <ULButton variant="outlined" onClick={() => navigate(ROUTES.APPLICATION_CONTINUE)} goBack={true}>{t('buttons.continue-application')}</ULButton>
                                        </Grid>
                                    </> :
                                    <>
                                        <Grid item xs={12}>
                                            <Typography variant="h5" component="h4" style={{ textDecoration: 'underline', fontWeight: '500' }}>
                                                {t('application-recover-omr.title')}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Accordion variant="outlined">
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreOutlined />}
                                                    aria-controls="call-panel-content"
                                                    id="call-panel-header"
                                                >
                                                    {t('application-recover-omr.call')}
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <FormControl fullWidth>
                                                        <RadioGroup
                                                            onChange={(e) => { setCallId(parseInt(e.target.value)) }}
                                                        >
                                                            {
                                                                callsList?.map((application, index) => (
                                                                    <FormControlLabel key={index} className="margin-height-auto radio-button" value={application.callId} control={<Radio checked={callId === application.callId} />} label={<div className="margin-top-bottom"> <div className="input-text">{application.publicName}</div> <div className="helperText" style={{ color: '#FF5117' }}>{application.description}</div> </div>} />
                                                                ))
                                                            }
                                                        </RadioGroup>
                                                    </FormControl>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Grid>
                                        <Grid item xs={12} spacing={2}>
                                            <Accordion variant="outlined">
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreOutlined />}
                                                    aria-controls="identification-panel-content"
                                                    id="identification-panel-header"
                                                >
                                                    {t('application-recover-omr.identification')}
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12}>
                                                            <FormControl fullWidth>
                                                                <InputLabel>{t('identification.document-type-placeholder')}</InputLabel>
                                                                <Select
                                                                    value={documentType === 0 ? undefined : documentType}
                                                                    onChange={handleChangeDocumentType}
                                                                >
                                                                    {
                                                                        documentTypeList?.map((documentType, index) => (
                                                                            <MenuItem key={index} value={documentType.documentTypeId}>{documentType.name}</MenuItem>
                                                                        ))
                                                                    }
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField inputProps={{ maxLength: isDNISelected ? 8 : undefined }} onChange={(e) => setDocumentNumber(e.target.value)} onKeyDown={handlePreventTypingChars} value={documentNumber} fullWidth label={t('identification.document-number-placeholder')} />
                                                        </Grid>
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Accordion variant="outlined">
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreOutlined />}
                                                    aria-controls="verification-panel-content"
                                                    id="verification-panel-header"
                                                >
                                                    {t('application-recover-omr.verification-method')}
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12}>
                                                            <FormControl fullWidth>
                                                                <RadioGroup
                                                                    value={requestCodeType}
                                                                    onChange={(e) =>
                                                                    {
                                                                        setRequestCodeType(parseInt(e.target.value))
                                                                    }}
                                                                >
                                                                    {
                                                                        requestCodeTypeList?.map((requestCodeType, index) => (
                                                                            <FormControlLabel key={index} className="radio-button" value={requestCodeType.requestTypeId} control={<Radio />} label={requestCodeType.name} />
                                                                        ))
                                                                    }
                                                                </RadioGroup>
                                                            </FormControl>
                                                        </Grid>
                                                        {
                                                            selectedCodeType === 'UNSELECTED' ?
                                                                <>
                                                                </> :
                                                                selectedCodeType === 'EMAIL' ?
                                                                    <>
                                                                        <Grid item xs={12}>
                                                                            <TextField onChange={(e) => handleChangeEmail(e)} value={email} fullWidth label={t('personal-data.email')} />
                                                                        </Grid>
                                                                        {
                                                                            invalidEmailFeedback ?
                                                                                <>
                                                                                    <Grid item xs={12}>
                                                                                        <div className="disclaimer validation-error"> {t('personal-data.email-validation-error')} </div>
                                                                                    </Grid>
                                                                                </> : <></>
                                                                        }
                                                                    </> : <>
                                                                        <Grid item xs={12}>
                                                                            <TextField onChange={(e) => handleChangePhone(e)} value={phone} fullWidth label={t('personal-data.phone-number')} onKeyDown={handlePreventInvalidPhoneNumbers} />
                                                                        </Grid>
                                                                    </>
                                                        }
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <ULButton disabled={
                                                requestCodeType === 0 || documentType === 0 || callId === 0 || !(isDNISelected ? documentNumber?.length === 8 : (documentNumber?.length > 0 && documentNumber?.length < 20))
                                            } working={working} onClick={handleContinue}>{t('application-recover-omr.recover-omr-btn')}</ULButton>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <ULButton variant="outlined" onClick={() => navigate(ROUTES.APPLICATION_CONTINUE, { state: { documentType: documentType, documentNumber: documentNumber, omr: omr } })} goBack={true}>{t('buttons.continue-application')}</ULButton>
                                        </Grid>
                                    </>
                            }
                        </Grid>
                    </>
            }
        </>
    )
}

export default ApplicationRecoverOMR;