
import { Card, CardContent, Grid, Stack, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import * as ROUTES from "../../routes/routes";
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";
import ApplicationSuspendStepIndicator from "../../components/ApplicationSuspendStepIndicator";
import ApplicationContext from "../../context/ApplicationContext";
import ULButton from "../../components/ULButton";
import * as APIServices from "../../services/rest/APIServices";
const ApplicationSuspend = (props) =>
{

    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const { OMR } = useContext(ApplicationContext);
    const step = location?.state?.step;
    const [callName, setCallName] = useState();
    const { applicationID } = useContext(ApplicationContext);

    const backToRegistration = () =>
    {
        if (step === 0)
        {
            navigate(ROUTES.STUDENT_PERSONAL_DATA)
        }
        else if (step === 1)
        {
            navigate(ROUTES.EDUCATION_FIFTH_SCHOOL_TYPE)
        }
        else if (step === 2)
        {
            navigate(ROUTES.MODALITY_AND_CARREERS_CHOOSE_MODALITY)
        }
        else if (step === 3)
        {
            navigate(ROUTES.DOCUMENTS_REQUESTED_DOCUMENTS)
        }
        else if (step === 4)
        {
            navigate(ROUTES.PAYMENTS)
        }
        else if (step === 5)
        {
            navigate(ROUTES.DOCUMENT_VERIFICATION)
        }
        else if (step === 6)
        {
            navigate(ROUTES.APPLICANTS_CARD)
        }
    }

    useEffect(() =>
    {
        const callServices = async () =>
        {
            await APIServices.getHeaderData(applicationID).then((response) =>
            {
                const callName = response.data?.data?.call;
                setCallName(callName);
            })
                .catch((error) =>
                {
                    console.log(error.response ? (error.response.data?.messages ?? [{ code: "", message: t('errors.unknown') }]) : [{ code: "", message: t('errors.network') }]);
                })
        }
        callServices();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const clearApplication = () =>
    {
        sessionStorage.clear();
        window.location.href = '/';
    }

    return (
        <Grid container direction="column">

            <Grid item>
                <Typography variant="h5" component="h4" style={{ fontWeight: '500' }}>
                    <span style={{ textDecoration: 'underline' }}>{t('application-suspend.title')}</span> ({callName})
                </Typography>
            </Grid>
            <Grid item className="min-space">
                <Card variant="outlined" style={{ border: '1px solid #FF5117', borderRadius: '.75rem' }}>
                    <CardContent sx={{ padding: 1, margin: 0 }} style={{ paddingBottom: 0 }}>
                        <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                            <ApplicationSuspendStepIndicator step={0} currentStep={step} text={t('stepper.applicant-information')} />
                        </Stack>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item className="min-space">
                <Card variant="outlined" style={{ border: '1px solid #FF5117', borderRadius: '.75rem' }}>
                    <CardContent sx={{ padding: 1, margin: 0 }} style={{ paddingBottom: 0 }}>
                        <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                            <ApplicationSuspendStepIndicator step={1} currentStep={step} text={t('stepper.school-information')} />
                        </Stack>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item className="min-space">
                <Card variant="outlined" style={{ border: '1px solid #FF5117', borderRadius: '.75rem' }}>
                    <CardContent sx={{ padding: 1, margin: 0 }} style={{ paddingBottom: 0 }}>
                        <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                            <ApplicationSuspendStepIndicator step={2} currentStep={step} text={t('stepper.careers')} />
                        </Stack>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item className="min-space">
                <Card variant="outlined" style={{ border: '1px solid #FF5117', borderRadius: '.75rem' }}>
                    <CardContent sx={{ padding: 1, margin: 0 }} style={{ paddingBottom: 0 }}>
                        <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                            <ApplicationSuspendStepIndicator step={3} currentStep={step} text={t('stepper.documents')} />
                        </Stack>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item className="min-space">
                <Card variant="outlined" style={{ border: '1px solid #FF5117', borderRadius: '.75rem' }}>
                    <CardContent sx={{ padding: 1, margin: 0 }} style={{ paddingBottom: 0 }}>
                        <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                            <ApplicationSuspendStepIndicator step={4} currentStep={step} text={t('stepper.payment')} />
                        </Stack>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item className="min-space">
                <Card variant="outlined" style={{ border: '1px solid #FF5117', borderRadius: '.75rem' }}>
                    <CardContent sx={{ padding: 1, margin: 0 }} style={{ paddingBottom: 0 }}>
                        <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                            <ApplicationSuspendStepIndicator step={5} currentStep={step} text={t('stepper.verification')} />
                        </Stack>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item className="min-space">
                <Card variant="outlined" style={{ border: '1px solid #FF5117', borderRadius: '.75rem' }}>
                    <CardContent sx={{ padding: 1, margin: 0 }} style={{ paddingBottom: 0 }}>
                        <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                            <ApplicationSuspendStepIndicator step={6} currentStep={step} text={t('stepper.applicant-card')} />
                        </Stack>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item className="min-space">
                <Typography variant="body2" component="p">
                    {t('application-suspend.ref-number-created-text')}
                </Typography>
                <Typography variant="h3" component="h1" textAlign="center">
                    {OMR}
                </Typography>
                <Typography variant="body2" component="p">
                    {t('application-suspend.ref-number-continue-text')}
                </Typography>
            </Grid>

            <Grid item className="min-space">
                <ULButton
                    fullWidth
                    onClick={clearApplication}
                >
                    {t('buttons.exit')}
                </ULButton>
            </Grid>

            <Grid item className="min-space" >
                <ULButton variant="outlined" fullWidth onClick={backToRegistration}>
                    {t('buttons.back-to-registration')}
                </ULButton>
            </Grid>

        </Grid>
    )
}

export default ApplicationSuspend;