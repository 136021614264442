import { createTheme } from "@mui/material/styles";
import { PRIMARY, SECONDARY, DEFAULT_WHITE, NEUTRAL, SUCCESS, ERROR, WARNING, BACKGROUND } from "./colors";

const theme = createTheme({
  palette: {
    background: {
      default: "#E1E1E1",
    },
    primary: {
      main: PRIMARY,
      contrastText: DEFAULT_WHITE,
    },
    secondary: {
      main: SECONDARY,
      contrastText: DEFAULT_WHITE,
    },
    neutral: {
      main: NEUTRAL,
      contrastText: DEFAULT_WHITE,
    },
    danger: {
      main: ERROR,
      contrastText: DEFAULT_WHITE,
    },
    warning: {
      main: WARNING,
      contrastText: DEFAULT_WHITE,
    },
    success: {
      main: SUCCESS,      
      contrastText: DEFAULT_WHITE,      
    }
  }
});

theme.typography.h1 = {
  fontSize: "96",
  fontFamily: "Roboto",
  fontWeight: "300"
};

theme.typography.h2 = {
  fontSize: "60",
  fontFamily: "Roboto",
  fontWeight: "300"
};

theme.typography.h3 = {
  fontSize: "48",
  fontFamily: "Roboto",
  fontWeight: 400
};

theme.typography.h4 = {
  fontSize: "34",
  fontFamily: "Roboto",
  fontWeight: 400
};

theme.typography.h5 = {
  fontSize: "24",
  fontFamily: "Roboto",
  fontWeight: 500
};

theme.typography.h6 = {
  fontSize: "20",
  fontFamily: "Roboto",
  fontWeight: 400
};

theme.typography.subtitle1 = {
  fontSize: "16",
  fontFamily: "Roboto",
  fontWeight: 400
};

theme.typography.subtitle2 = {
  fontSize: "14",
  fontFamily: "Roboto",
  fontWeight: 500
};

theme.typography.button = {
  fontSize: "14",
  fontFamily: "Roboto",
  fontWeight: 500
};

theme.typography.caption = {
  fontSize: "12",
  fontFamily: "Roboto",
  fontWeight: 400
};

theme.typography.overline = {
  fontSize: "12",
  fontFamily: "Roboto",
  fontWeight: 400
};

theme.components.MuiBottomNavigationAction = {
  styleOverrides: {
    root: {
      font: (theme) => theme.typography.h6,
      color: BACKGROUND,
      minWidth:"65px",
    },
  },
};

theme.components.MuiTextField = {
  styleOverrides: {
    root: {
      borderColor: 'red'
    },
  },
};

export default theme;
