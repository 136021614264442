import React, { createRef } from 'react'
import AvatarEditor from 'react-avatar-editor';
import { v4 as uuidv4 } from 'uuid';
import Dropzone from 'react-dropzone';
import './UserAvatarEditor.css';
import isImageFormatSupported from '../../utils/IsImageFormatSupported';
import { Alert, Button, Grid, Slider, Stack, Typography } from '@mui/material';
import { ZoomIn, ZoomOut } from '@mui/icons-material';
import ULButton from '../ULButton';
import { styled } from '@mui/material/styles';
import i18next from 'i18next';


const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

class UserAvatarEditor extends React.Component
{
    constructor (props)
    {
        super(props);
        this.uuid = uuidv4();
        this.editor = createRef();
        this.state = {
            image: '',
            position: { x: 0.5, y: 0.5 },
            scale: 1,
            preview: undefined,
            unsupported: false,
            showInfo: true,
        }
    }

    static defaultProps = {
        height: 288,
        width: 240,
    }

    handleNewImage = (e) =>
    {
        const file = e.target.files?.[0];
        if (file)
        {
            if (isImageFormatSupported(file.type))
            {
                this.setState({
                    image: file,
                    unsupported: false,
                });
                this.props.callBack && this.props.callBack(true);
            }
            else
            {
                this.setState({
                    unsupported: true,
                })
            }
        }
    }

    readAsBase64 = (file) =>
    {
        return new Promise((resolve, reject) =>
        {
            const fileReader = new FileReader();

            fileReader.onload = (event) =>
            {
                resolve(event.target.result);
            };

            fileReader.onerror = (error) =>
            {
                reject(error);
            };

            fileReader.readAsDataURL(file);
        });
    }

    getPicture = async () =>
    {
        if (this.state.image === '') return null;
        let base64 = null;
        let base64O = null;
        let scaled_image = this.editor.current?.getImageScaledToCanvas().toDataURL('image/jpeg');
        const base64OImage = await this.readAsBase64(this.state.image);
        if (scaled_image && base64OImage)
        {
            base64 = scaled_image.split(",")[1];
            base64O = base64OImage.split(",")[1];
        }
        return ({
            image: this.state.image,
            base64: base64,
            base64Original: base64O,
            name: this.state.image.name
        });
    }

    handleScale = (e) =>
    {
        const scale = parseFloat(e.target.value);
        this.setState({ scale });
    }

    handlePositionChange = (position) =>
    {
        this.setState({ position })
    }

    handleZoomIn = () =>
    {
        this.setState((prevState) => ({ scale: (prevState.scale + 0.1 < 2 ? prevState.scale + 0.1 : 2) }));
    }

    handleZoomOut = () =>
    {
        this.setState((prevState) => ({ scale: (prevState.scale - 0.1 > 1 ? prevState.scale - 0.1: 1) }));
    }

    render ()
    {
        return (
            <>
                <Grid container item spacing={2} style={{ position: 'relative' }}>
                    {
                        this.state.unsupported ?
                            <Grid item xs={12}>
                                <Alert role="alert" severity="error">
                                    <Typography>{i18next.t('user-avatar-editor.unsupported-format')}</Typography>
                                </Alert>
                            </Grid>
                            : <></>
                    }
                    <Grid item xs={12} style={{ position: 'relative' }}>
                        <Dropzone
                            onDrop={([image]) => this.setState({ image })}
                            multiple={false}
                            noClick={true}
                            accept={{ 'image/*': [] }}
                        >
                            {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps()} style={{ width: this.props.width + 20, position: 'relative', margin: '0 auto' }} className={`user-avatar-preview ${this.state.image === '' ? '' : 'user-avatar-preview-z-n1'}`} data-content={this.state.image === '' ? i18next.t('user-avatar-editor.drag-picture') : ''}>
                                    <AvatarEditor
                                        ref={this.editor}
                                        scale={this.state.scale}
                                        width={this.props.width}
                                        height={this.props.height}
                                        position={this.state.position}
                                        onPositionChange={this.handlePositionChange}
                                        backgroundColor='#ffffff'
                                        image={this.state.image}
                                        border={10}
                                        className='border'
                                        disableCanvasRotation={true}
                                        style={{ background: 'transparent', marginLeft: 'auto', marginRight: 'auto', borderRadius: '.5rem', display: 'block' }}
                                    />
                                    {
                                        this.state.image !== '' ?
                                            <>
                                                <div className='picture-placeholder' data-content={`(${this.props.width}, ${this.props.height})`}></div>
                                            </> : <></>
                                    }
                                    <input
                                        name="newImage"
                                        type="file"
                                        onChange={this.handleNewImage}
                                        {...getInputProps()}
                                    />
                                </div>
                            )}
                        </Dropzone>
                    </Grid>
                    {
                        this.state.image !== '' ?
                            <>
                                <Grid item xs={12} >
                                    <div className='user-avatar-controls-container'>
                                        <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                                            <Button variant='link' onClick={this.handleZoomOut}><ZoomOut /></Button>
                                            <Slider min={1} max={2} step={0.01} value={this.state.scale} onChange={this.handleScale} />
                                            <Button variant='link' onClick={this.handleZoomIn}><ZoomIn /></Button>
                                        </Stack>
                                    </div>
                                </Grid>
                            </> : <></>
                    }
                    <Grid item xs={12}>
                        <div className='user-avatar-controls-container'>
                            <ULButton component="label" variant={this.state.image !== '' ? 'outlined' : 'contained'}>
                                {this.state.image !== '' ? i18next.t('user-avatar-editor.select-another-picture') : i18next.t('user-avatar-editor.select-picture')}
                                <VisuallyHiddenInput type="file" accept="image/*" onChange={this.handleNewImage} name="newImage" />
                            </ULButton>
                        </div>
                    </Grid>
                </Grid>
            </>
        )
    }
}

export default UserAvatarEditor;