import { Alert, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import * as APIServices from "../../services/rest/APIServices";
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as ROUTES from "../../routes/routes";
import Loading from '../../components/Loading';
import { useContext } from 'react';
import ApplicationContext from '../../context/ApplicationContext';
import FileUploadManager from '../../components/FileUploadManager';
import { v4 as uuidv4 } from 'uuid';
import ULButton from '../../components/ULButton';
import { SCROLLABLE_AREA_NAME, canUserEditInformation } from '../../utils/CommonFunctions';

const EducationUniversityNotFound = () =>
{
  const { applicationID } = useContext(ApplicationContext);
  const [errMessages, setErrMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = React.useState([]);
  const [working] = useState(false);
  const [canEditFiles, setCanEditFiles] = useState(true);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const uploadFile = (file, tempId) =>
  {
    const options = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent) =>
      {
        const { loaded, total } = progressEvent;
        const percentCompleted = Math.round((loaded * 100) / total);
        setFiles((prevFiles) => (prevFiles.map((file) =>
        {
          if (file.tempId === tempId)
          {
            file.progress = percentCompleted;
          }
          return file;
        })));
      },
    }
    const data = new FormData();
    data.append('file', file);
    data.append('applicationId', applicationID);

    APIServices.uploadUniversityFileNF(data, options)
      .then((response) =>
      {
        const data = response.data.data;
        setFiles((prevFiles) => (prevFiles.map((file) =>
        {
          if (file.tempId === tempId)
          {
            file.uploaded = true;
            file.uuid = data.uuid;
            file.sizeText = data.sizeText;
          }
          return file;
        })));
      })
      .catch((error) =>
      {
        const errMessages = error.response ? error.response.data?.messages ?? [{ code: "", message: t('errors.unknown') }] : [{ code: "", message: t('errors.network') }];
        setFiles((prevFiles) => (prevFiles.map((file) =>
        {
          if (file.tempId === tempId)
          {
            file.errMessages = errMessages;
          }
          return file;
        })));
      })
  }

  const isAlreadyUploaded = (accepted) =>
  {
    return files.some(file =>
      file.name === accepted.name
    );
  }

  const handleSetFiles = (files) =>
  {
    setFiles(files.map((file) =>
    {
      if (!file.tempId && !file.uploaded && !file.progress)
      {
        if (!isAlreadyUploaded(file))
        {
          const uuid = uuidv4();
          uploadFile(file, uuid);
          file.tempId = uuid;
          file.uploaded = false;
          file.progress = 0;
          file.canEdit = true;
        }
        else
        {
          const errMessages = [{ code: "", message: t('education.common.file-already-exists-error', { filename: file.name }) }]
          file.already = true;
          file.errMessages = errMessages;
        }
      }
      return file;
    }));
  }

  const handleDeleteFile = (file) =>
  {
    if (file.already)
    {
      setFiles((prevFiles) => (prevFiles.filter((f) => f !== file)));
    }
    else
    {
      APIServices.deleteUniversityFileNF({
        applicationId: applicationID,
        uuid: file.uuid,
      })
        .then((response) =>
        {
          setFiles((prevFiles) => (prevFiles.filter((f) => f !== file)));
        })
        .catch((error) =>
        {
          const errMessages = error.response ? error.response.data?.messages ?? [{ code: "", message: t('errors.unknown') }] : [{ code: "", message: t('errors.network') }];
          setFiles((prevFiles) => (prevFiles.map((pfile) =>
          {
            if (pfile.uuid === file.uuid)
            {
              pfile.errMessages = errMessages;
            }
            return pfile;
          })));
        })
    }
  }

  const handleDownloadFile = (file) =>
  {
    APIServices.downloadUniversityFileNF(applicationID, file.uuid)
      .then((response) =>
      {
        const imageContentType = response.headers['content-type'];
        const imageFilename = response.headers.ulima_filename;
        const imageBinary = new Blob([response.data], { type: imageContentType });
        const imageUrl = URL.createObjectURL(imageBinary);
        const dlink = document.createElement("a");
        dlink.href = imageUrl;
        dlink.download = imageFilename;
        dlink.target = "_blank";
        dlink.style.display = "none";
        document.body.appendChild(dlink);
        dlink.click();
        URL.revokeObjectURL(imageUrl);
        document.body.removeChild(dlink);
      })
      .catch((error) =>
      {
        const errMessages = error.response ? error.response.data?.messages ?? [{ code: "", message: t('errors.unknown') }] : [{ code: "", message: t('errors.network') }];
        setFiles((prevFiles) => (prevFiles.map((pfile) =>
        {
          if (pfile.uuid === file.uuid)
          {
            pfile.errMessages = errMessages;
          }
          return pfile;
        })));
      })
  }

  const listDocuments = () =>
  {
    setIsLoading(false);
    APIServices.listUniversityFilesNF(applicationID)
      .then((response) =>
      {
        const files = response.data.data?.list;
        if (files?.length)
        {
          setFiles(files.map(file =>
          {
            const { fileName, ...rest } = file;
            return { name: fileName, uploaded: fileName.length ? true : false, ...rest };
          }));
        }
        const status = response.data.data?.status;
        setCanEditFiles(canUserEditInformation(status));
      })
      .catch((error) =>
      {
        setErrMessages(error.response ? error.response.data?.messages ?? [{ code: "", message: t('errors.unknown') }] : [{ code: "", message: t('errors.network') }])
        const scrollableArea = document.getElementById(SCROLLABLE_AREA_NAME);
        scrollableArea.scrollTo({ top: 0, behavior: 'smooth' });
        window.scrollTo({ top: 0, behavior: 'smooth' });
      })
      .finally(() =>
      {
        setIsLoading(false);
      })
  }

  useEffect(() =>
  {
    listDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const navigateTo = () =>
  {
    navigate(ROUTES.MODALITY_AND_CARREERS_CHOOSE_CAREER);
  }

  const handleContinue = (e) =>
  {
    navigateTo();
  }

  return (
    <>
      {
        isLoading ?
          <>
            <Loading />
          </> :
          <>
            <Grid container direction="column" spacing={2}>
              {
                errMessages?.length ?
                  <Grid item xs={12}>
                    <Alert role="alert" severity="error">
                      {
                        errMessages.map((err, index) => (
                          <Typography key={index}> {err.code?.length ? `[${err.code}]` : ""} {err.message}</Typography>
                        ))
                      }
                    </Alert>
                  </Grid>
                  : <></>
              }
              {
                !canEditFiles ?
                  <>
                    <Grid item xs={12}>
                      <Alert role="alert" severity="warning">
                        <Typography>{t('common.already-confirmed')}</Typography>
                      </Alert>
                    </Grid>
                  </> : <></>
              }
              <Grid container item spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h5" component="h4" style={{ textDecoration: 'underline', fontWeight: '500' }}>
                    {t('education.institution-not-found.title')}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <ul style={{ margin: 0 }}>
                    <li>{t('education.institution-not-found.notepad')}</li>
                    <li>{t('education.institution-not-found.certificate-studies')}</li>
                    <li>{t('education.institution-not-found.voucher')}</li>
                    <li>{t('education.institution-not-found.other-document')}</li>
                  </ul>
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <FileUploadManager
                    canEdit={canEditFiles}
                    multiple={true}
                    files={files}
                    setFiles={handleSetFiles}
                    deleteFile={handleDeleteFile}
                    downloadFile={handleDownloadFile}
                    title={''}
                    description={''}
                    fileType={
                      {
                        'application/pdf': [],
                        'image/jpeg': [],
                        'application/msword': [],
                        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
                      }
                    }
                  />
                </Grid>
                <Grid container item spacing={2}>
                  <Grid item xs={12}>
                    <ULButton
                      working={working}
                      onClick={handleContinue}
                      disabled={
                        files.length < 1 || !files?.every(file => file.uploaded === true)
                      }
                    >
                      {t('buttons.continue')}
                    </ULButton>
                  </Grid>
                  <Grid item xs={12}
                  >
                    <ULButton
                      variant="outlined"
                      onClick={() => navigate(ROUTES.MODALITY_AND_CARREERS_UNIVERSITY_TYPE)}
                      fullWidth
                      goBack={true}
                    >
                      {t('buttons.back')}
                    </ULButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
      }
    </>
  );
}

export default EducationUniversityNotFound;