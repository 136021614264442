import axios from 'axios';
import { getAccessToken, setAccessToken } from '../../utils/CommonFunctions';
const baseURL = process.env.REACT_APP_API_ENDPOINT;
const apiAccessKey = process.env.REACT_APP_API_ACCESS_KEY;
const apiSecretKey = process.env.REACT_APP_API_SECRET_KEY;
const authAPI = "api/v1/security/authenticate";

const http = axios.create({
    baseURL: baseURL,
    timeout: 300000,
    headers: {
        Authorization: getAccessToken()
            ? 'Bearer ' + getAccessToken()
            : null,
        'Content-Type': 'application/json',
        accept: 'application/json',
    },
});

http.interceptors.response.use(
    (response) =>
    {
        return response;
    },
    async function (error)
    {
        const originalRequest = error.config;
        if (typeof error.response === 'undefined')
        {
            return Promise.reject(error);
        }

        if (error.response.status === 403 && error.response.data === "")
        {
            return http
                .post(authAPI, {
                    accessKey: apiAccessKey,
                    secretKey: apiSecretKey,
                }, {
                    headers: {
                        Authorization: null,
                        'Content-Type': 'application/json',
                        accept: 'application/json',
                    }
                })
                .then((response) =>
                {
                    setAccessToken(response.data.data.token);
                    http.defaults.headers['Authorization'] =
                        'Bearer ' + response.data.data.token;
                    originalRequest.headers['Authorization'] =
                        'Bearer ' + response.data.data.token;
                    return http(originalRequest);
                })
                .catch((err) =>
                {
                    console.log(err);
                });
        }
        return Promise.reject(error);
    }
);

export default http;