import { Alert, Box, Button, Card, CardContent, Grid, IconButton, List, ListItem, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import * as React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from "react-i18next";
import { CheckCircle, CloudUploadOutlined, Pending } from '@mui/icons-material';
import LinearProgress from '@mui/material/LinearProgress';
import { Fragment } from 'react';
import { makeStyles } from '@mui/styles';
import { VERIFICATION_INVALID, VERIFICATION_PENDING, VERIFICATION_VALID } from '../../views/DocumentVerification/VerifiedDocuments';
import { v4 as uuidv4 } from 'uuid';
import ULButton from '../ULButton';

const useStyles = makeStyles((theme) => ({
  successCard: {
    backgroundColor: theme.palette.success.light,
    color: '#fff',
  },
  successTypography: {
    color: theme.palette.success.dark,
  },
  warningCard: {
    backgroundColor: theme.palette.warning.light,
  },
  warningTypography: {
    color: theme.palette.warning.dark,
  },
  errorCard: {
    backgroundColor: theme.palette.error.light,
    color: '#fff',
  },
  errorTypography: {
    color: theme.palette.error.dark,
  },
}));

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const LinearProgressWithLabel = (props) =>
{
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const UploadBox = styled(Box)(
  ({ theme }) => `
    border-radius: '6px';
    padding: 2;
    background: ${theme.palette.background};
`
);

export default function FileUploadManagerVerification({ files, templates, setFiles, deleteFile, downloadFile, downloadTemplate, multiple, fileType, title, description, onlyAccepted, partial, canEdit, errMessages, kind })
{
  const { t } = useTranslation();

  const fumThemes = useStyles();

  const onDrop = (acceptedFiles) =>
  {
    if (onlyAccepted)
      setFiles(acceptedFiles);
    else
      setFiles(multiple ? [...files, ...acceptedFiles] : acceptedFiles);
  };

  const onCancel = (file) =>
  {
    deleteFile(file);
  };

  const onDownload = (file) =>
  {
    downloadFile(file);
  }

  const onTemplateDownload = (template) =>
  {
    downloadTemplate(template);
  }
  const disabled = !canEdit || (!files.length && !templates.every((template) => template.downloaded === true));
  const { getRootProps, getInputProps } = useDropzone({ onDrop: onDrop, accept: fileType, multiple: multiple, disabled: disabled });
  const vUuid = uuidv4();

  return (
    <>
      <Grid
        item
        xs={12}
      >
        <Card variant='outlined' sx={{ borderRadius: '.75rem', borderColor: '#ff5117' }}>
          <CardContent sx={{ padding: 2, paddingBottom: '0!important', marginBottom: 2 }}>
            {
              title.length ?
                <>
                  <Typography variant="body1" component="p" style={{ fontWeight: 500 }}>
                    {title}
                  </Typography>
                </> : <></>
            }
            {
              description.length ?
                <>
                  <Typography variant="body2" component="p">
                    {description}
                  </Typography>
                </> : <></>
            }
            {
              files.length === 0 & !canEdit ?
                <>
                  <Typography variant='body2' component='p' >{t('file-upload-manager.no-files')}</Typography>
                </> : <></>
            }
            <UploadBox>
              {
                errMessages?.length ?
                  <Grid item xs={12}>
                    <Alert role="alert" severity="error" sx={{ wordWrap: 'break-word', mt: 1 }}>
                      {
                        errMessages.map((err, index) => (
                          <Typography key={index}> {err.code?.length ? `[${err.code}]` : ""} {err.message}</Typography>
                        ))
                      }
                    </Alert>
                  </Grid> : <></>
              }
              {
                templates?.length ?
                  <Grid item xs={12} sx={{ mt: 1 }}>
                    <Alert icon={false} severity="warning">
                      <Typography variant='h5' component='h4'>{t('file-upload-manager.templates')}</Typography>
                      {
                        templates.map((template, index) => (
                          <Button fullWidth key={index} variant='outlined' underline="hover" onClick={() => { onTemplateDownload(template) }}>{template.fileName}</Button>
                        ))
                      }
                    </Alert>
                  </Grid> : <></>
              }
              {files.length > 0 && (
                <>
                  <List disablePadding>
                    {files.map((file, index) => (
                      <Fragment key={`${vUuid}-${index}`} >
                        {
                          (file.name?.length || file.errMessages?.length) ?
                            <>
                              <Card variant='outlined' sx={{ borderRadius: '.5rem', marginTop: 1 }} className={
                                file.documentState === VERIFICATION_PENDING ?
                                  fumThemes.warningCard :
                                  file.documentState === VERIFICATION_INVALID ?
                                    fumThemes.errorCard :
                                    file.documentState === VERIFICATION_VALID ?
                                      fumThemes.successCard : ''
                              }>
                                <CardContent sx={{ padding: 0, paddingX: 2, paddingBottom: '0!important' }}>
                                  <ListItem disableGutters sx={{ paddingBottom: 0, paddingTop: 0 }} component="div" secondaryAction={
                                    (!file.uploaded || !canEdit) ? undefined :
                                      <>
                                        {
                                          kind === "req" ?
                                            <>
                                              <IconButton aria-label="delete" onClick={() => { onCancel(file) }}>
                                                <DeleteIcon />
                                              </IconButton>
                                            </> :
                                            <>
                                              <IconButton aria-label="delete" onClick={() => { file.documentState === VERIFICATION_INVALID && onCancel(file) }}>
                                                {
                                                  file.documentState === VERIFICATION_PENDING ?
                                                    <>
                                                      <Pending />
                                                    </> :
                                                    file.documentState === VERIFICATION_INVALID ?
                                                      <>
                                                        <DeleteIcon sx={{ color: '#fff' }} />
                                                      </> :
                                                      file.documentState === VERIFICATION_VALID ?
                                                        <>
                                                          <CheckCircle sx={{ color: '#fff' }} />
                                                        </> :
                                                        <>
                                                        </>
                                                }
                                              </IconButton>
                                            </>
                                        }
                                      </>
                                  }>
                                    <Grid container sx={{ paddingY: 2, alignItems: 'center' }}>
                                      {
                                        !file.errMessages?.length ?
                                          <>
                                            <Grid item xs={8}>
                                              {
                                                file.uploaded ?
                                                  <>
                                                    <Typography variant='body2' component='p' style={{ wordBreak: 'break-word', fontWeight: 600 }} onClick={() => { onDownload(file) }}><span style={{ cursor: 'pointer' }} className={`${file.documentState ? '' : 'download-link'}`}>{file.name}</span></Typography>
                                                  </> :
                                                  <>
                                                    <Typography variant='body2' component='p' style={{ wordBreak: 'break-word', fontWeight: 600 }}>{file.name}</Typography>
                                                  </>
                                              }
                                            </Grid>
                                            <Grid item xs={4}>
                                              <Typography variant='body2' component='p' textAlign='right' style={{ color: `${file.documentState ? '' : '#777'}` }} >{file.sizeText ?? ''}</Typography>
                                            </Grid>
                                            {
                                              !file.uploaded ?
                                                <>
                                                  <Grid item xs={12}>
                                                    <LinearProgressWithLabel value={file.progress} />
                                                  </Grid>
                                                </> : <></>
                                            }
                                          </> : <></>
                                      }
                                      {
                                        file.errMessages?.length ?
                                          <Grid item xs={12}>
                                            <Alert role="alert" severity="error" sx={{ wordWrap: 'break-word' }}>
                                              {
                                                file.errMessages.map((err, index) => (
                                                  <Typography key={index}> {err.code?.length ? `[${err.code}]` : ""} {err.message}</Typography>
                                                ))
                                              }
                                            </Alert>
                                          </Grid> : <></>
                                      }
                                    </Grid>
                                  </ListItem>
                                </CardContent>
                              </Card>
                              {
                                file.documentState === VERIFICATION_PENDING ?
                                  <Typography variant='body2' component='p' className={fumThemes.warningTypography}>{t('document-verification.status-pending')}</Typography> :
                                  file.documentState === VERIFICATION_INVALID ?
                                    <Typography variant='body2' component='p' className={fumThemes.errorTypography}>{file.observations?.length ? file.observations : t('document-verification.status-invalid')}</Typography> :
                                    file.documentState === VERIFICATION_VALID ?
                                      <Typography variant='body2' color='success' component='p' className={fumThemes.successTypography}>{t('document-verification.status-valid')}</Typography> :
                                      <></>
                              }
                            </>
                            : <></>
                        }
                      </Fragment>
                    ))}
                  </List>
                </>
              )}
              {
                !partial && canEdit ?
                  <>
                    <Grid item xs={12} sx={{ mt: 1 }}>
                      <div {...getRootProps()}>
                        <ULButton
                          disabled={disabled}
                          onClick={(e) => { e.preventDefault() }}
                          component="label"
                          role={undefined}
                          variant="link"
                          tabIndex={-1}
                          sx={{ marginLeft: 'auto' }}
                          startIcon={<CloudUploadOutlined />}
                          fullWidth={false}
                        >
                          {!files.length ? t('document-verification.click-to-upload') : t('document-verification.click-to-upload-another')}
                          <VisuallyHiddenInput {...getInputProps()} type="file" />
                        </ULButton>
                      </div>
                    </Grid>
                  </> : <></>
              }
            </UploadBox>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
}

FileUploadManagerVerification.defaultProps = {
  files: [],
  templates: [],
  setFiles: () => { },
  deleteFile: () => { },
  multiple: true,
  onlyAccepted: false,
  partial: false,
  canEdit: true,
  kind: "req",
}