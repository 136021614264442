
import { Dialog, DialogActions, DialogContent, Grid, Slide, Typography } from "@mui/material";
import { useState, useEffect, useContext, forwardRef } from "react";
import { Alert } from '@mui/material';
import { useNavigate } from "react-router-dom";
import * as ROUTES from "../../routes/routes";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { useTranslation } from "react-i18next";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import * as APIServices from "../../services/rest/APIServices";
import ApplicationContext from "../../context/ApplicationContext";
import { canUserEditInformation, SCROLLABLE_AREA_NAME, stepRoute } from "../../utils/CommonFunctions";
import Loading from "../../components/Loading";
import { handlePreventTypingChars } from "../../utils/Validations";
import PersonalInformationContext from "../../context/PersonalInformationContext";
import ULButton from "../../components/ULButton";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const StudentIdentification = (props) => {
    const { applicationID, callID, setCallID, setApplicationID } = useContext(ApplicationContext);
    const { documentNumber, setDocumentNumber, documentType, setDocumentType } = useContext(PersonalInformationContext);
    const [applicationIDCP, setApplicationIDCP] = useState(0);

    const { t } = useTranslation();
    const navigate = useNavigate();
    const [dtype, setDtype] = useState(-1);
    const [showInputID, setShowInputID] = useState(false);
    const [condition, setCondition] = useState(false);
    const [showInputDni, setShowInputDni] = useState(false);
    const [documentTypeList, setDocumentTypeList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [errMessages, setErrMessages] = useState([]);
    const [working, setWorking] = useState(false);
    const [canEdit, setCanEdit] = useState(true);
    const [openExistingApplicationModal, setOpenExistingApplicationModal] = useState(false);
    const [existingDocumentType, setExistingDocumentType] = useState("");
    const [existingDocumentNumber, setExistingDocumentNumber] = useState("");
    const [existingCallName, setExistingCallName] = useState("");

    function evaluateStudent(e) {
        if (!canEdit) {
            navigate(ROUTES.STUDENT_PERSONAL_DATA);
        }
        else {
            if (!conditionGetter()) return;
            const data = {
                "callId": callID,
                "documentTypeId": documentType,
                "documentNumber": documentNumber,
            }
            applicationID && (data.applicationId = applicationID);
            setWorking(true);
            setErrMessages([]);
            APIServices.saveApplicantDocument(data)
                .then((response) => {
                    const data = response.data.data;
                    if (data.hasExistingApplication) {
                        // Muestra una alerta y le redirige a la pantalla de continuar postulación
                        setExistingDocumentNumber(data.documentNumber);
                        setExistingDocumentType(data.documentType);
                        setExistingCallName(data.callName);
                        handleOpenExistingApplicationModal();
                    }
                    else {
                        // Continua normalmente
                        setApplicationID(data.applicationId ?? 0);
                        navigate(stepRoute(10));
                    }
                })
                .catch((error) => {
                    setErrMessages(error.response ? error.response.data?.messages ?? [{ code: "", message: t('errors.unknown') }] : [{ code: "", message: t('errors.network') }])
                    const scrollableArea = document.getElementById(SCROLLABLE_AREA_NAME);
                    scrollableArea.scrollTo({ top: 0, behavior: 'smooth' });
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                })
                .finally(() => {
                    setWorking(false);
                })
        }
    }

    function conditionGetter() {
        if (dtype === 100) return documentType > 0 && documentNumber.length > 0 && documentNumber.length < 20;
        return documentNumber.length === 8;
    }

    function showInputText(choice) {
        setDtype(parseInt(choice));
        if (parseInt(choice) === 100) {
            setShowInputDni(false);
            setShowInputID(true);
            setDocumentType(0);
        }
        else {
            setShowInputDni(true);
            setShowInputID(false);
            setDocumentType(parseInt(choice));
        };
    }

    const handleChangeDocumentType = (e) => {
        setDocumentType(parseInt(e.target.value));
    }

    const fetchData = async () => {
        if (!callID) {
            setErrMessages([]);
            return;
        }
        if (!applicationID) return;
        setApplicationIDCP(applicationID);
        setIsLoading(true);
        await APIServices.fetchApplicantDocument(applicationID)
            .then((response) => {
                const data = response.data.data;
                if (data) {
                    setDocumentNumber(data.documentNumber);
                    showInputText(data.isMain ? data.documentTypeId : 100);
                    setDocumentType(data.documentTypeId);
                    setCallID(data.callId ?? 0);
                }
                const status = response.data.data?.status;
                setCanEdit(canUserEditInformation(status));
            })
            .catch((error) => {
                // No se pudo obtener la info (aún no ha sido registrada)
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    useEffect(() => {
        setCondition(conditionGetter())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [documentType, documentNumber, dtype]);

    useEffect(() => {
        const callServices = async () => {
            setIsLoading(true);
            await APIServices.getDocumentTypeList().then((response) => {
                setDocumentTypeList(response.data.data?.list ?? []);
            })
                .catch((error) => {
                    setErrMessages(error.response ? (error.response.data?.messages ?? [{ code: "", message: t('errors.unknown') }]) : [{ code: "", message: t('errors.network') }]);
                    const scrollableArea = document.getElementById(SCROLLABLE_AREA_NAME);
                    scrollableArea.scrollTo({ top: 0, behavior: 'smooth' });
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                })
                .finally(() => {
                    setIsLoading(false);
                })
            await fetchData();
        }
        callServices();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCloseExistingApplicationModal = () => {
        setOpenExistingApplicationModal(false);
    }

    const handleOpenExistingApplicationModal = () => {
        setOpenExistingApplicationModal(true);
    }

    const handleRedirectContinueApplication = () => {
        navigate(ROUTES.APPLICATION_CONTINUE, { state: { documentNumber: documentNumber, documentType: documentType } });
    }

    return (
        <>
            {
                isLoading ?
                    <>
                        <Loading />
                    </> :
                    <>
                        <Grid container direction="column" spacing={2}>
                            {
                                errMessages?.length ?
                                    <Grid item xs={12}>
                                        <Alert role="alert" severity="error">
                                            {
                                                errMessages.map((err, index) => (
                                                    <Typography key={index}> {err.code?.length ? `[${err.code}]` : ""} {err.message}</Typography>
                                                ))
                                            }
                                        </Alert>
                                    </Grid>
                                    : <></>
                            }
                            {
                                !canEdit ?
                                    <>
                                        <Grid item xs={12}>
                                            <Alert role="alert" severity="warning">
                                                <Typography>{t('common.already-confirmed')}</Typography>
                                            </Alert>
                                        </Grid>
                                    </> : <></>
                            }
                            <Grid item xs={12}>
                                <Typography variant="h5" component="h4">
                                    {t('identification.document-type-label')}
                                </Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <RadioGroup
                                        value={dtype}
                                    >
                                        {
                                            documentTypeList?.map((documentType, index) => (
                                                documentType.isMain && <FormControlLabel disabled={!canEdit} onChange={(e) => { showInputText(e.target.value); }} key={index} className="radio-button" value={documentType.documentTypeId} control={<Radio />} label={documentType.name} />
                                            ))
                                        }
                                        <FormControlLabel disabled={!canEdit} onChange={(e) => { showInputText(e.target.value); }} className="radio-button" value="100" control={<Radio />} label={t('identification.document-type-option-other')} />

                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            {showInputDni &&
                                <>
                                    <Grid item xs={12}>
                                        <TextField disabled={!canEdit} inputProps={{ maxLength: 8 }} onChange={(e) => setDocumentNumber(e.target.value)} onKeyDown={handlePreventTypingChars} value={documentNumber} fullWidth label={t('identification.document-number-placeholder')} />
                                    </Grid>
                                </>
                            }

                            {showInputID &&
                                <Grid item xs={12}>

                                    <FormControl fullWidth>
                                        <InputLabel id="document-type-label">{t('identification.document-type-placeholder')}</InputLabel>
                                        <Select
                                            value={documentType === 0 ? undefined : documentType}
                                            onChange={handleChangeDocumentType}
                                            disabled={!canEdit}
                                        >
                                            {
                                                documentTypeList?.map((documentType, index) => (
                                                    !documentType.isMain && <MenuItem key={index} value={documentType.documentTypeId}>{documentType.name}</MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                            }

                            {showInputID &&
                                <Grid item xs={12}>
                                    <TextField disabled={!canEdit} onChange={(e) => setDocumentNumber(e.target.value)} onKeyDown={handlePreventTypingChars} value={documentNumber} fullWidth label={t('identification.document-number-placeholder')} />
                                </Grid>
                            }

                            <Grid item xs={12}>
                                <ULButton disabled={!condition} working={working} onClick={evaluateStudent}>{t('buttons.continue')} </ULButton>
                            </Grid>

                            <Grid item xs={12}>
                                <ULButton variant="outlined" onClick={() => navigate(ROUTES.APPLICATION_NEW_APPLICANT)} goBack={true}>{t('buttons.back')} </ULButton>
                            </Grid>
                        </Grid>
                        <Dialog
                            open={openExistingApplicationModal}
                            TransitionComponent={Transition}
                            keepMounted
                            onClose={handleCloseExistingApplicationModal}
                            PaperProps={{ sx: { borderRadius: "1rem" } }}
                        >
                            <DialogContent sx={{px: 4, pb: 0}} style={{ fontFamily: 'Roboto' }}>
                                <p>{t('identification.application-in-process', { documentNumber: existingDocumentNumber, documentType: existingDocumentType, callName: existingCallName })}</p>
                            </DialogContent>
                            <DialogActions sx={{p: 4}}>
                                <Grid container item spacing={2}>
                                    <Grid item xs={12}>
                                        <ULButton fullWidth onClick={handleRedirectContinueApplication}>{t('identification.continue-applicantion-btn')}</ULButton>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <ULButton variant="outlined" fullWidth onClick={handleCloseExistingApplicationModal}>{t('identification.correct-applicantion-btn')}</ULButton>
                                    </Grid>
                                </Grid>
                            </DialogActions>
                        </Dialog>
                    </>
            }
        </>
    )
}

export default StudentIdentification;