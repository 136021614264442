
import { Alert, Grid, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import * as ROUTES from "../../routes/routes";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from "react";
import { SCROLLABLE_AREA_NAME, protectEmail, protectPhoneNumber } from "../../utils/CommonFunctions";
import * as APIServices from "../../services/rest/APIServices";
import ApplicationContext from "../../context/ApplicationContext";
import Loading from "../../components/Loading";
import ULButton from "../../components/ULButton";

const StudentChooseVerificationType = (props) =>
{
    const { applicationID } = useContext(ApplicationContext);

    const navigate = useNavigate();
    const { t } = useTranslation();
    const [option, setOption] = useState(-1);
    const location = useLocation();
    const cellphone = location.state?.cellphone;
    if (!cellphone) navigate(ROUTES.STUDENT_PERSONAL_DATA);
    const email = location.state?.email;
    if (!email) navigate(ROUTES.STUDENT_PERSONAL_DATA);
    const [requestCodeTypeList, setRequestCodeTypeList] = useState([]);
    const [errMessages, setErrMessages] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [working, setWorking] = useState(false);

    const requestCode = (e) =>
    {
        setWorking(true);
        setErrMessages([]);
        APIServices.requestVerificationCode({
            applicationId: applicationID,
            requestTypeId: option,
        })
            .then((response) =>
            {
                // El código ha sido solicitado y se está a la espera
                // const data = response.data.data;
                const showCode = response.data?.data?.showCode ?? false;
                const code = response.data?.data?.code ?? '';
                const requestType = requestCodeTypeList.find((requestType) => (requestType.requestTypeId === option));
                let instructions = requestType?.message.replace('<:email>', protectEmail(email));
                instructions = instructions.replace('<:phone>', protectPhoneNumber(cellphone));
                navigate(ROUTES.STUDENT_CODE_VALIDATION, { state: { instructions: instructions, cellphone: cellphone, email: email, requestTypeId: option, showCode: showCode, codePreview: code } });
            })
            .catch((error) =>
            {
                console.log(error);
                setErrMessages(error.response ? error.response.data?.messages ?? [{ code: "", message: t('errors.unknown') }] : [{ code: "", message: t('errors.network') }])
                const scrollableArea = document.getElementById(SCROLLABLE_AREA_NAME);
                scrollableArea.scrollTo({ top: 0, behavior: 'smooth' });
                window.scrollTo({ top: 0, behavior: 'smooth' });
            })
            .finally(() => {
                setWorking(false);
            })
    }

    const isSelectedOption = (e) =>
    {
        if (option >= 0) requestCode(e);
    }

    useEffect(() =>
    {
        const callServices = async () =>
        {
            setIsLoading(true);
            await APIServices.getRequestCodeTypeList().then((response) =>
            {
                setRequestCodeTypeList(response.data.data?.list ?? []);
            })
                .catch((error) =>
                {
                    setErrMessages(error.response ? (error.response.data?.messages ?? [{ code: "", message: t('errors.unknown') }]) : [{ code: "", message: t('errors.network') }]);
                    const scrollableArea = document.getElementById(SCROLLABLE_AREA_NAME);
                    scrollableArea.scrollTo({ top: 0, behavior: 'smooth' });
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                })
                .finally(() =>
                {
                    setIsLoading(false);
                })
        }
        callServices();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {
                isLoading ?
                    <>
                        <Loading />
                    </> :
                    <>
                        <Grid container direction="column" spacing={2}>
                            {
                                errMessages?.length ?
                                    <Grid item xs={12}>
                                        <Alert role="alert" severity="error">
                                            {
                                                errMessages.map((err, index) => (
                                                    <Typography key={index}> {err.code?.length ? `[${err.code}]` : ""} {err.message}</Typography>
                                                ))
                                            }
                                        </Alert>
                                    </Grid>
                                    : <></>
                            }

                            <Grid item xs={12}>
                                <Typography variant="h5" component="h4" style={{ textDecoration: 'underline', fontWeight: '500' }}>
                                    {t('code-verification.verification-send.title')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <RadioGroup
                                        value={option}
                                        onChange={(e) =>
                                        {
                                            setOption(parseInt(e.target.value))
                                        }}
                                    >
                                        {
                                            requestCodeTypeList?.map((requestCodeType, index) => (
                                                <FormControlLabel key={index} className="radio-button" value={requestCodeType.requestTypeId} control={<Radio />} label={requestCodeType.name} />
                                            ))
                                        }
                                    </RadioGroup>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <ULButton disabled={option === -1} working={working} onClick={(e) => isSelectedOption(e)}>{t('buttons.continue')}</ULButton>
                            </Grid>

                            <Grid item xs={12}>
                                <ULButton variant="outlined" onClick={() => navigate(ROUTES.STUDENT_PERSONAL_DATA)} goBack={true}>{t('buttons.back')}</ULButton>
                            </Grid>
                        </Grid>
                    </>
            }
        </>
    )
}

export default StudentChooseVerificationType;